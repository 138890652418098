import axios from "axios";
import React, { useEffect, useState } from "react";

function ConversationItem(props) {
    const [redDot, setRedDot] = useState(false);
    const chatNotification = localStorage.getItem("mtChatNotification");

    const handleClick = (event) => {
        props.onData(props.conversation);
        props.activeName(props.name);

        const conversationList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");

        if (redDot) {
            setRedDot(false);
            const parsedLocalStorage = JSON.parse(chatNotification);
            const localIdReceiver = parsedLocalStorage.id_receiver
            let roomKeysArray = [];

            if (chatNotification !== null) {
                roomKeysArray = parsedLocalStorage.room_keys;
            }

            const filteredRoomKeyArray = roomKeysArray.filter((item) => item !== props.roomKey);

            const obj = {
                room_keys: filteredRoomKeyArray,
                id_receiver: localIdReceiver,
                notification: true
            }

            if (filteredRoomKeyArray.length === 0) {
                localStorage.removeItem("mtChatNotification");
            } else {
                localStorage.setItem("mtChatNotification", JSON.stringify(obj));
            }

            axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/update-notification", {
                params: {
                    room_key: props.roomKey,
                    notification: false
                }
            }).then(function (res) {
            }).catch(function (err) {
            })
        }
    }

    useEffect(() => {
        if (chatNotification !== null) {

            const parsedLocalStorage = JSON.parse(chatNotification);

            if (parsedLocalStorage.notification === true) {
                addRedDot(parsedLocalStorage.room_keys, parsedLocalStorage.id_receiver, props.idUserToNotify);
            }
        }
    }, [chatNotification]);

    const addRedDot = (room_keys, id_receiver, idUserToNotify) => {
        if (room_keys.includes(props.roomKey) && id_receiver === idUserToNotify) {
            setRedDot(true);
        }
    }

    return (
        <div className="conversation-item mt-4" onClick={(event) => handleClick(event)}>
            <img style={{ objectFit: "cover" }} src={props.photo === null || props.photo === undefined ? "../static/icons/person-missing.svg" : `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(props.photo).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt="user" />
            <div className="conversation-item-content">
                <p className={`conversation-item-p conversation-item-name`}>{props.name !== null ? props.name : "\u00A0"}</p>
                <div className="conversation-item-separator"></div>
                <p className={`conversation-item-p conversation-item-last-message text-muted`}>{props.lastMessage ? props.lastMessage : "Nouveau chat"}</p>
                {redDot &&
                    <div className="red-dot-conversation-item"></div>
                }
            </div>
        </div>
    )
}

export default ConversationItem;