import React from 'react';
import CheckBoxField from '../CheckBoxField/CheckBoxField';

const AIAttachmentsSelect = ({ attachments, selectedAttachments, onChange }) => {
  const handleCheckboxChange = (e) => {
    onChange(e.id);
  };

  return (
    <div className="attachment-selector">
      {attachments.map((attachment) => (
        <CheckBoxField
          key={attachment.id}
          id={attachment.id}
          name={attachment.id}
          label={attachment.filename}
          onChange={handleCheckboxChange}
          value={selectedAttachments.includes(attachment.id)}
          disabled={
            selectedAttachments.length === 3 && !selectedAttachments.includes(attachment.id)
          }
        />
      ))}
    </div>
  );
};

export default AIAttachmentsSelect;
