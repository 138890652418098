import React from 'react'

import { Modal } from "../../../components/atoms/Modal/Modal"
import { archiveConsultant } from '../../../api/ConsultantApi'

const ManagerConsultantArchive = ({ consultantId, onClose }) => {
  const handleSubmit = async () => {
    await archiveConsultant(consultantId)
    window.location.reload()
    onClose();
  }

  return (
    <Modal
      onClosePopin={onClose}
      openPopin={true}
      title={"Archiver compte consultant"}
      content={"Voulez-vous archiver ce compte consultant ?"}
      cancelButton={"Annuler"}
      actionButton={"Archiver"}
      handleActionButton={handleSubmit}
    />
  )
}

export default ManagerConsultantArchive