import React from 'react'

import CircleButton from '../../atoms/CircleButton/CircleButton'
import Icon from '../../atoms/Icon/Icon'
import './EmptyCard.scss';

const EmptyCard = ({ id, onClick = () => { }, className = "", disabled = false }) => {
  const handleClick = (e) => {
    if (disabled) {
      return
    }
    onClick({ id })
  }

  return (
    <div className={`empty-card ${className && className}`} style={{ cursor: !disabled && 'pointer', "--empty-card-border-color": disabled && "var(--grey)" }} onClick={handleClick}>
      <CircleButton icon={Icon.icon.PersonPlus} disabled={disabled} id={id} onClick={handleClick} />
    </div>
  )
}

export default EmptyCard;