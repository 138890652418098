import React from 'react'

import { Modal } from "../../../components/atoms/Modal/Modal"
import { activateConsultant } from '../../../api/ConsultantApi'

const ManagerConsultantActivate = ({ consultantId, onClose }) => {
  const handleSubmit = async () => {
    await activateConsultant(consultantId)
    window.location.reload()
    onClose();
  }

  return (
    <Modal
      onClosePopin={onClose}
      openPopin={true}
      title={"Activation compte consultant"}
      content={"Voulez-vous activer ce compte consultant ?"}
      cancelButton={"Annuler"}
      actionButton={"Activer"}
      handleActionButton={handleSubmit}
    />
  )
}

export default ManagerConsultantActivate