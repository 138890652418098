import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import DateField from '../../molecules/DateField/DateField';
import InputField from '../../molecules/InputField/InputField';
import ComboField from '../../molecules/ComboField/ComboField';
import Icon from '../../atoms/Icon/Icon';
import moment from 'moment';
import 'moment/locale/fr';

import './AccompCoSystemCard.scss';
import RadioField from '../../molecules/RadioField/RadioField';

const AccompCoSystemCard = ({
  title,
  icon = null,
  locale,
  readOnly,
  onChange,
  entrySystem,
  joinDate,
  duration,
  signedMembershipCharter,
  signedEEO,
  startDate,
  endDate,
  situations,
  endRealDate,
  handicapSituation,
}) => {
  const [durationState, setDurationState] = useState({ years: null, months: null, days: null });

  const handleChange = (newValue) => {
    if (!readOnly) {
      onChange(newValue);
    }
  };

  const handleCalculateDuration = (newValue) => {
    if (!readOnly) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    const parseDate = (dateString) => {
      const momentDate = moment(dateString, ['DD/MM/YYYY', 'YYYY-MM-DD HH:mm:ss'], true);
      if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY');
      }

      return dateString;
    };

    if (startDate.value && endDate.value) {
      const parsedStartDate = parseDate(startDate.value);
      const parsedEndDate = parseDate(endDate.value);

      const startDateMoment = moment(parsedStartDate, 'DD/MM/YYYY');
      const endDateMoment = moment(parsedEndDate, 'DD/MM/YYYY');
      const years = endDateMoment.diff(startDateMoment, 'years');
      startDateMoment.add(years, 'years'); // Soustraire les années
      const months = endDateMoment.diff(startDateMoment, 'months');
      startDateMoment.add(months, 'months'); // Soustraire les mois
      const days = endDateMoment.diff(startDateMoment, 'days');

      setDurationState({ years: years, months: months, days: days });
    }
  }, [startDate.value, endDate.value]);

  const formatDuration = (durationState) => {
    const { years, months, days } = durationState;

    let formattedDuration = '';
    if (years !== null && years !== 0) {
      formattedDuration += `${years} an${years !== 1 ? 's' : ''} `;
    }
    if (months !== null && months !== 0) {
      formattedDuration += `${months} mois `;
    }
    if (days !== null && days !== 0) {
      formattedDuration += `${days} jour${days !== 1 ? 's' : ''}`;
    }
    console.log(formattedDuration.trim());
    return formattedDuration.trim();
  };

  const handleHandicapChange = (value) => {
    // Log avant d'appeler onChange
    
    // S'assurer que l'ID correct est passé
    onChange(handicapSituation.id, value);
  };

  const {
    subtitle,
    entrySystemLabel,
    joinDateLabel,
    durationLabel,
    signedMembershipCharterLabel,
    signedEEOLabel,
    startDateLabel,
    endDateLabel,
    monthLabels,
    dayShortLabels,
    handicapSituationLabel,
  } = locale;
  return (
    <div className="accomp-collective-system-card">
      <IconCardTitle icon="paperboard" color="accent" label={title} />
      <div className="accomp-collective-system-card-content">
        <DateField
          title={entrySystemLabel}
          name={entrySystem.id}
          value={entrySystem.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
        />

        <DateField
          title={joinDateLabel}
          name={joinDate.id}
          value={joinDate.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
        />

        <ComboField
          title={signedMembershipCharterLabel}
          name={signedMembershipCharter.id}
          key={signedMembershipCharter.id}
          value={signedMembershipCharter.value}
          options={signedMembershipCharter.options}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <ComboField
          title={signedEEOLabel}
          name={signedEEO.id}
          key={signedEEO.id}
          value={signedEEO.value}
          options={signedEEO.options}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <ComboField
          title={subtitle}
          name={situations.id}
          key={situations.id}
          value={situations.value}
          options={situations.options}
          onChange={handleChange}
          readOnly={readOnly}
        />

        <DateField
          title={startDateLabel}
          name={startDate.id}
          value={startDate.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
          disableAfterDate={endDate.value}
        />

        <DateField
          title={endDateLabel}
          name={endDate.id}
          value={endDate.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
          disablePreviousDate={startDate.value}
        />

        <InputField
          title={durationLabel}
          name={duration.id}
          value={formatDuration(durationState)}
          onChange={handleChange}
          type="string"
          readonly={true}
        />

        <DateField
          title={'Date de fin réelle'}
          name={endRealDate.id}
          value={endRealDate.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={handleChange}
          locale={locale}
          readOnly={readOnly}
          disablePreviousDate={startDate.value}
        />
        <RadioField
          title={handicapSituationLabel}
          id={handicapSituation.id}
          name={handicapSituation.id}
          value={handicapSituation.value ?? ''}
        //   options={handicapSituation.options}
          inputList={[
            { id: `${handicapSituation.id}_yes`, value: 'yes', label: 'Oui' },
            { id: `${handicapSituation.id}_no`, value: 'no', label: 'Non' },
          ]}
          onChange={onChange}
        />
        {/* <ComboField
          title={handicapSituationLabel}
          name={handicapSituation.id}
          key={handicapSituation.id}
          value={handicapSituation.value}
          options={handicapSituation.options}
          onChange={handleChange}
          readOnly={readOnly}
        /> */}
      </div>
    </div>
  );
};

AccompCoSystemCard.propTypes = {
  title: PropTypes.string,
  entrytSystem: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  joinDate: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  duration: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  signedMembershipCharter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  signedEEO: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  startDate: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  endDate: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
};
export default AccompCoSystemCard;
