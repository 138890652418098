/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../components/atoms/LoadingSpinner/LoadingSpinner'
import NotificationList from '../containers/global/NotificationList/NotificationList'
import NavigationRouter from '../navigation/NavigationRouter'
import {
  actions as AppActions,
  selectors as AppSelectors,
} from '../redux/AppRedux'

import {selectors as ColorThemeSelectors } from "../redux/ColorThemeRedux";

import '../stylesheets/main.css'
import '../stylesheets/main.scss'

import { usePortalCustomization } from '../hooks/usePortalCustomization'

const ConnectedApp = () => {
  const dispatch = useDispatch()
  const started = useSelector(AppSelectors.started)
  const loading = useSelector(AppSelectors.loading)
  const themeStore = useSelector(ColorThemeSelectors.getTheme);

  // eslint-disable-next-line no-unused-vars
  const _ = usePortalCustomization({ office: null })

  const handleWindowResize = useCallback(() => {
    dispatch(AppActions.updateSize())
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  if (!started) {
    return null
  }
  return (
    <div className={"App"} data-theme={themeStore ? themeStore : "mytalents"}>
      <NavigationRouter />
      <NotificationList />

      {loading && <LoadingSpinner />}
    </div>
  )
}
export default ConnectedApp
