/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateSelector from '../DateSelector/DateSelector'
import InputField from '../InputField/InputField'
import './DateField.scss';

import 'moment/locale/fr'

class DateField extends Component {
  static propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    calendarLabels: PropTypes.shape({
      dayShortLabels: PropTypes.arrayOf(PropTypes.string),
      monthLabels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    locale: PropTypes.string,
    readOnly: PropTypes.bool,
    disablePreviousToday: PropTypes.bool,
    disablePreviousDate: PropTypes.string,
    disableAfterDate: PropTypes.string,
  }

  static defaultProps = {
    required: false,
    title: '',
    placeholder: '',
    onChange: () => {},
    locale: 'fr',
    readOnly: false,
    disablePreviousToday: false,
    disablePreviousDate: null,
    disableAfterDate: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      fieldFocused: false,
    }

    this.dateFieldDom = null
    this.getFormatedDate = this.getFormatedDate.bind(this)
    this.onSelectDate = this.onSelectDate.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  innerRef = (element) => {
    this.dateFieldDom = element
  }

  getFormatedDate(value) {
    // const { locale } = this.props
    const displayFormat = 'DD/MM/YYYY'
    const date = new Date(value)
    if (value === '' || value == null || (moment(value).isValid() === false && moment(value, 'YYYY-MM-DD').isValid() === false && moment(value, 'DD/MM/YYYY').isValid() === false)) {
      return null
    }

    if (value.length < displayFormat.length || date.toString() === 'Invalid Date') {
      return value
    }

    // moment.locale(locale, {
    //   parentLocale: locale,
    //   months: this.props.calendarLabels.monthLabels,
    // })

    if (value.length === displayFormat.length) {
      return moment(value, displayFormat).format(displayFormat)
    }

    return moment(new Date(value)).format(displayFormat)
  }

  componentDidMount() {
    document.addEventListener('touchend', this.handleClick, true)
    document.addEventListener('click', this.handleClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('touchend', this.handleClick, true)
    document.removeEventListener('click', this.handleClick, true)
  }

  handleClick(e) {
    const el = this.dateFieldDom
    const fieldFocused = el.contains(e.target)

    this.setState({ fieldFocused })
  }

  onSelectDate(value) {
    if (this.props.onChange) {
      this.props.onChange({ id: this.props.name, value: this.getFormatedDate(value) })
    }

    setTimeout(() => {
      this.setState({ fieldFocused: false })
    }, 200)
  }

  handleDateInputChange = ({ value }) => {
    if (!this.props.readOnly) {
      if (this.props.onChange) {
        this.props.onChange({ id: this.props.name, value })
      }

      setTimeout(() => {
        this.setState({ fieldFocused: false })
      }, 200)
    }
  }

  renderDateSelector() {
    const { value, calendarLabels, locale, disablePreviousToday } = this.props

    if (!this.state.fieldFocused) {
      return null
    }
    let dateSelectorValue = moment().format();
    if (value) {
      const dateValue = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'], true);
      if (dateValue.isValid()) {
        dateSelectorValue = dateValue.format();
      }
    }
    return (
      <div className="date-selector-wrapper">
        <DateSelector
          value={dateSelectorValue}
          locale={calendarLabels}
          onChange={this.onSelectDate}
          disablePreviousToday={disablePreviousToday}
          disablePreviousDate={this.props.disablePreviousDate}
          disableAfterDate={this.props.disableAfterDate}
        />
      </div>
    )
  }

  render() {
    const { title, placeholder, name, value, required, readOnly } = this.props
    return (
      <div className="date-field" ref={this.innerRef}>
        <InputField
          title={title}
          placeholder={placeholder}
          name={name}
          required={required}
          value={this.getFormatedDate(value)}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onChange={this.handleDateInputChange}
          readonly={readOnly}
        />
        {!readOnly && this.renderDateSelector()}
      </div>
    )
  }
}

export default DateField
