import React, { useEffect, useState } from 'react';
import './BeneficiaryTallySheetSign.scss';
import { getTallysheetState, validateTallysheetAppointment } from '../../api/AppointmentsApi';
import Logo from '../../static/assets/logo-my-talents-white.svg';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import backgroundImage from '../../static/images/background-login.webp';
import InputField from '../../components/molecules/InputField/InputField';

const BeneficiaryTallySheetSign = (props) => {
  const [status, setStatus] = useState();
  const [message, setMessage] = useState("");
  const [signingCode, setSigningCode] = useState("");

  useEffect(() => {
    getTallysheetState(props.match.params.tallysheet_id).then((res) => {
      if (res.tallysheet_is_signed) {
        setStatus(200)
        setMessage("Vous avez déjà signé la feuille d'émargement.")
      }
    })
  }, [props.match.params.tallysheet_id]);

  const handleChangeCode = (e) => {
    setSigningCode(e.value);
  }

  const handleSign = () => {
    const data = {
      signing_code: signingCode
    }

    validateTallysheetAppointment(props.match.params.tallysheet_id, data)
      .then((res) => {
        setStatus(res.status);
        setMessage(res.message);
      })
  }

  return (
    <div className="beneficiary-tally-sheet-page">
      <div className="img-wrapper">
        <img src={backgroundImage} alt="presentation-my-talents" />
        <div></div>
      </div>
      <div className="tallysheet-wrapper">
        <Logo className="logo" />

        <div className="modal-tallysheet">
          <div className="tallysheet-message-wrapper">
            {status === 200 && message ? (
              <p className="modal-tallysheet-title-success">
                {message}
              </p>
            ) : (
              <div className="tallysheet-input-wrapper">
                <h3>Saisissez le code reçu par mail</h3>
                <InputField onChange={handleChangeCode} type="text" name="signingCode" placeholder="Saisir le code reçu par mail" value={signingCode} />
                {status === 403 && message &&
                  <p className="modal-tallysheet-title-error">{message}</p>
                }
                <PrimaryButton id="sign" label="Confirmer ma présence" onClick={handleSign} />
              </div>
            )}
          </div>
          <p>Retrouvez votre rendez-vous sur votre espace MyTalents </p>
          <PrimaryButton id="redirect_button" label="Mon espace" onClick={() => props.history.push('/')} />
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryTallySheetSign;
