import React, { useRef } from 'react';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import './TextAreaEditor.scss';

const TextAreaEditor = ({ name, value = '', onChange }) => {
  const editorRef = useRef(null);

  const apiKeyTinyMCE = process.env.REACT_APP_API_KEY_TINYMCE;
  if (!apiKeyTinyMCE) {
    console.warn(
      "La clé API pour TinyMCE est manquante. Assurez-vous de la définir dans vos variables d'environnement.",
    );
  }

  return (
    <TinyMCEEditor
      apiKey={apiKeyTinyMCE}
      value={value}
      onEditorChange={(content) => onChange(content)}
      onInit={(_evt, editor) => {
        editorRef.current = editor;
      }}
      init={{
        height: '100%',
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'help',
          'wordcount',
        ],
        resize: false,
        toolbar:
          'undo redo | blocks | bold italic forecolor | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'bullist numlist outdent indent | table tabledelete | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
    />
  );
};

export default TextAreaEditor;
