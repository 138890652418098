import { selectors as OfficeSelectors } from '../../../../redux/OfficeRedux';
import { useSelector } from 'react-redux';
import React from 'react';
import moment from 'moment';
import { contentRef } from './PrintableContent';
import { forwardRef, useState, useEffect } from 'react';
import { getConsultantInfos } from '../../../../api/ConsultantApi';
import { accompanimentTypes } from '../../../../pages/ConsultantBeneficiaryAccompanimentPage/AccompanimentFields/AccompanimentTypeFields';
import { getUserProfile } from '../../../../api/ProfileApi';

const PrintableContent = forwardRef(({ data, beneficiary }, ref) => {
  const office = useSelector(OfficeSelectors.office);
  const [profile, setProfile] = useState({});
  useEffect(async () => {
    const profileData = await getUserProfile(beneficiary.id);
    setProfile(profileData.profile[profileData.profile.selected_accompaniment]);
  }, []);

  const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").isValid()
      ? moment(dateString, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")
      : 'n/a';
  };
  return (
    <div ref={ref}>
      {/* Votre contenu à convertir en PDF */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img
          className="office-logo"
          style={{ width: '20%' }}
          src={`${process.env.REACT_APP_ASSETS_API_STATIC_URL
            }${beneficiary.consultants[0].office.image_url?.replace(
              process?.env?.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
              '',
            )}`}
          alt={office?.name}
        />
      </div>
      <h4 style={{ paddingTop: '20px' }}>
        Feuille d'émargement - {beneficiary.last_name} {beneficiary.first_name}
      </h4>
      <p>
        Type d'action :{' '}
        {profile?.accompaniment_type ? accompanimentTypes.find((type) => type.value === profile?.accompaniment_type ?? "")?.label : "n/a"}
      </p>
      {beneficiary.accompaniment_collective_type ? (
        <p>Dispositif : {beneficiary.accompaniment_collective_type}</p>
      ) : (
        ''
      )}
      <p>Dates : {profile?.accompaniment_start_date ? formatDate(profile.accompaniment_start_date) : 'n/a'} à {profile?.accompaniment_end_date ? formatDate(profile.accompaniment_end_date) : "n/a"} </p>
      <p> durée asynchrone : {profile?.accompaniment_asynchronous_time ?? "n/a"}</p>
      <p> durée synchrone : {profile?.accompaniment_synchronous_time ?? "n/a"}</p>
      <p>
        Consultant / Formateur :{' '}
        {beneficiary.consultants.find((e) => e.is_primary === true).last_name}{' '}
        {beneficiary.consultants.find((e) => e.is_primary === true).first_name}
      </p>
      <div style={{ height: '50px' }}></div>
      {data.map((item, index) => {
        if (item) {
          const user = item.user_from;
          return (
            <>
              <div key={index} className="agenda-tallysheet-pdf" style={{ padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {/* <img
                    className="office-logo"
                    style={{ width: '20%' }}
                    src={`${
                      process.env.REACT_APP_ASSETS_API_STATIC_URL
                    }${office?.image_url?.replace(
                      process?.env?.REACT_APP_OLD_UPLOADS_API_STATIC_URL,
                      '',
                    )}`}
                    alt={office?.name}
                  /> */}
                </div>
                {/* <h4 style={{ paddingTop: '20px' }}>Feuille d'émargement</h4> */}
                <p>
                  Rendez-vous{' '}
                  {item.appointment.appointment_type === 'Individual' ? 'individuel' : 'collectif'}{' '}
                  : {item.appointment.title}
                </p>
                <p>
                  Rendez-vous du{' '}
                  {moment(item.appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')} à{' '}
                  {moment(item.appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                </p>
                <p>
                  {item.appointment.videocall_type === 'none'
                    ? 'Rendez-vous presentiel'
                    : 'Rendez-vous distanciel : ' + item.appointment.videocall_url}
                </p>
                <p>
                  Organisé par : {user.first_name} {user.last_name} #{user.id}
                </p>
                <div style={{ backgroundColor: 'black', height: '1px', width: '100%' }}></div>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Prénom</th>
                      <th>Email</th>
                      <th>État</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email}</td>
                      <td>
                        {item.appointment.user_from_tallysheet_is_signed
                          ? 'Signé électroniquement le ' +
                          moment(
                            item.appointment.user_from_tallysheet_date_signed,
                            'YYYY-MM-DD HH:mm:ss',
                          ).format('DD/MM/YYYY') +
                          ' à ' +
                          moment(
                            item.appointment.user_from_tallysheet_date_signed,
                            'YYYY-MM-DD HH:mm:ss',
                          ).format('HH:mm')
                          : ''}
                      </td>
                    </tr>
                    {item.appointment.users_to.individuals.map((participant) => (
                      <tr>
                        <td>{participant.first_name}</td>
                        <td>{participant.last_name}</td>
                        <td>{participant.email}</td>
                        <td>
                          {participant.tallysheet_is_signed
                            ? 'Signé électroniquement le ' +
                            moment(
                              participant.tallysheet_date_signed,
                              'YYYY-MM-DD HH:mm:ss',
                            ).format('DD/MM/YYYY') +
                            ' à ' +
                            moment(
                              participant.tallysheet_date_signed,
                              'YYYY-MM-DD HH:mm:ss',
                            ).format('HH:mm')
                            : ''}
                        </td>
                      </tr>
                    ))}
                    {item.appointment.users_to.groups.map((group) =>
                      group.group_users.map((participant) => (
                        <tr>
                          <td>{participant.first_name}</td>
                          <td>{participant.last_name}</td>
                          <td>{participant.email}</td>
                          <td>
                            {participant.tallysheet_is_signed
                              ? 'Signé électroniquement le ' +
                              moment(
                                participant.tallysheet_date_signed,
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('DD/MM/YYYY') +
                              ' à ' +
                              moment(
                                participant.tallysheet_date_signed,
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('HH:mm')
                              : ''}
                          </td>
                        </tr>
                      )),
                    )}
                  </tbody>
                </table>
              </div>
            </>
          );
        }
      })}
      <div style={{ paddingTop: '25px' }}>
        <p style={{ textAlign: 'center' }}>
          Je soussigné {beneficiary.consultants.find((e) => e.is_primary === true).last_name}{' '}
          {beneficiary.consultants.find((e) => e.is_primary === true).first_name} atteste que{' '}
          {beneficiary.last_name} {beneficiary.first_name} a bien réalisé l’intégralité des étapes
          de la formation à distance en asynchrone, dont la durée de réalisation a préalablement été
          calculée.
        </p>
      </div>
    </div>
  );
});

export default PrintableContent;
