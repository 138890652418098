/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelectors } from '../../../redux/UserRedux';
import './BeneficiaryAccompaniment.scss';
import 'moment/locale/fr';

import {
  AccompanimentTypeLocale,
  accompanimentTypes,
  appraisalTypes,
  appraisalAvailabilities,
  vaeAvailabilities,
  termsList,
  AccompanimentCoJobLocale,
  AccompanimentCoSystemLocale,
  optionsYesNo,
  AccompanimentCoFollowupProjectLocale,
  optionsSitutation,
  optionsNumbers,
  AccompCoFormationLocale,
  optionsTypeFormation,
  optionsOpinionConsultant,
  optionsFinancing,
  AccompanimentCoSolutionLocale,
  optionsSolutions,
  optionsDifferentialRemuneration,
  activityOptions,
  typeCollectiveAccompaniment,
  situationFollowUpList,
  formationList,
} from '../../../pages/ConsultantBeneficiaryAccompanimentPage/AccompanimentFields/index';
import { getUserProfile } from '../../../api/ProfileApi';
import AccompEditionCard from '../../../components/organisms/AccompEditionCard/AccompEditionCard';
import { reduxForm } from 'redux-form';

import { asyncValidate } from '../../../helpers/form/FormValidator/FormValidator';
import AccompCoSystemCard from '../../../components/organisms/AccompCoSystemCard/AccompCoSystemCard';
import CurrentJobCard from '../../../components/organisms/CurrentJobCard/CurrentJobCard';
import FollowupProjectCard from '../../../components/organisms/FollowupProjectCard/FollowupProjectCard';
import FormationCard from '../../../components/organisms/FormationCard/FormationCard';
import SolutionCard from '../../../components/organisms/SolutionCard/SolutionCard';

function BeneficiaryAccompaniment() {
  const user = useSelector(UserSelectors.user);
  const [currentAccompaniment, setCurrentAccompaniment] = useState('');
  const [financement, setFinancement] = useState('');
  const [formations, setFormations] = useState({});


  useEffect(() => {
    getUserProfile(user.id).then((response) => {
      const res = response.profile;
      setFormations(res.formations);
      const acc = response.profile[response.profile.selected_accompaniment];
      setFinancement(response.profile.financial);
      for (const prop in acc) {
        if (
          acc.hasOwnProperty(prop) &&
          typeof acc[prop] === 'string' &&
          acc[prop].includes('0000-00-00 00:00:00')
        ) {
          acc[prop] = formatDate(acc[prop]);
        }
      }
      setCurrentAccompaniment(acc);
    });
  }, []);

  const formatDate = (dateString) => {
    if (dateString === '0000-00-00 00:00:00') {
      return '';
    }
    return dateString;
  };

  const renderAppraisalAccompaniment = () => {
    return (
      <>
        <AccompEditionCard
          readOnly={true}
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: currentAccompaniment.accompaniment_type,
            options: accompanimentTypes,
          }}
          startDate={{
            id: 'accompaniment_start_date',
            value: currentAccompaniment.accompaniment_start_date,
          }}
          endDate={{
            id: 'accompaniment_end_date',
            value: currentAccompaniment.accompaniment_end_date,
          }}
          dateSixMonths={{
            id: 'accompaniment_date_six_months',
            value: currentAccompaniment.accompaniment_date_six_months,
          }}
          accompanimentSurveyCount={{
            id: 'accompaniment_survey_count',
            value: currentAccompaniment.accompaniment_survey_count,
          }}
          accompanimentSurveyType={{
            id: 'accompaniment_survey_type',
            value: currentAccompaniment.accompaniment_survey_type,
          }}
          bilanType={{
            id: 'accompaniment_appraisal_type',
            value: currentAccompaniment.accompaniment_appraisal_type,
            options: appraisalTypes,
          }}
          bilanFranceTravailId={{
            id: 'accompaniment_appraisal_france_travail_id',
            value: currentAccompaniment.accompaniment_appraisal_france_travail_id,
          }}
          bilanAvailability={{
            id: 'accompaniment_appraisal_availability',
            value: currentAccompaniment.accompaniment_appraisal_availability,
            options: appraisalAvailabilities,
          }}
          homeOffice={{
            id: 'accompaniment_home_office',
            value: currentAccompaniment.accompaniment_home_office,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: currentAccompaniment.accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: currentAccompaniment.accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: currentAccompaniment.accompaniment_global_time,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: currentAccompaniment.accompaniment_first_appointment_date,
          }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: currentAccompaniment.accompaniment_appraisal_situation_follow_up
              ? currentAccompaniment.accompaniment_appraisal_situation_follow_up
              : 'training_or_training_project',
            options: situationFollowUpList,
          }}
          terms={{
            id: 'accompaniment_terms',
            value: currentAccompaniment.accompaniment_terms
              ? currentAccompaniment.accompaniment_terms
              : 'face_to_face',
            options: termsList,
          }}
          group={{
            id: 'accompaniment_group',
            value: currentAccompaniment.accompaniment_group,
            options: [],
          }}
        />

      </>
    );
  };

  const renderOutplacementCollective = () => {
    return (
      <>
        <AccompEditionCard
          readOnly={true}
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: currentAccompaniment.accompaniment_type,
            options: accompanimentTypes,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
          }}
        />

        <div className="beneficiary-outplacement-individual">
        <AccompCoSystemCard
            title={'Dispositif préalable'}
            entrySystem={{
              id: 'accompaniment_co_system_prior',
              value: currentAccompaniment.accompaniment_co_system_prior,
            }}
            joinDate={{
              id: 'accompaniment_co_join_date_prior',
              value: currentAccompaniment.accompaniment_co_join_date_prior,
            }}
            duration={{
              id: 'accompaniment_co_duration_prior',
              value: currentAccompaniment.accompaniment_co_duration_prior,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_co_signed_membership_charter_prior',
              value: currentAccompaniment.accompaniment_co_signed_membership_charter_prior,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_co_signed_eeo_prior',
              value: currentAccompaniment.accompaniment_co_signed_eeo_prior,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_co_start_date_prior',
              value: currentAccompaniment.accompaniment_co_start_date_prior,
            }}
            endDate={{
              id: 'accompaniment_co_end_date_prior',
              value: currentAccompaniment.accompaniment_co_end_date_prior,
            }}
            endRealDate={{
              id: 'accompaniment_co_real_end_date_prior',
              value: currentAccompaniment.accompaniment_co_real_end_date_prior,
            }}
            readOnly={true}
            situations={{
              id: 'accompaniment_co_situations_prior',
              value: currentAccompaniment.accompaniment_co_situations_prior,
              options: optionsSitutation,
            }}
            handicapSituation={{
              id: 'accompaniment_co_handicap_situation_prior',
              value: currentAccompaniment.accompaniment_co_handicap_situation_prior,
              options: optionsYesNo,
            }}
            locale={AccompanimentCoSystemLocale}
          />
          <AccompCoSystemCard
            title={'Dispositif'}
            entrySystem={{
              id: 'accompaniment_outplacement_individual_system',
              value: currentAccompaniment.accompaniment_outplacement_individual_system,
            }}
            joinDate={{
              id: 'accompaniment_outplacement_individual_join_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_join_date,
            }}
            duration={{
              id: 'accompaniment_outplacement_individual_duration',
              value: currentAccompaniment.accompaniment_outplacement_individual_duration,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_outplacement_individual_signed_membership_charter',
              value:
                currentAccompaniment.accompaniment_outplacement_individual_signed_membership_charter,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_outplacement_individual_signed_eeo',
              value: currentAccompaniment.accompaniment_outplacement_individual_signed_eeo,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_outplacement_individual_start_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_start_date,
            }}
            endDate={{
              id: 'accompaniment_outplacement_individual_end_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_end_date,
            }}
            endRealDate={{
              id: 'accompaniment_outplacement_individual_real_end_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_real_end_date,
            }}
            handicapSituation={{
              id: 'accompaniment_outplacement_individual_handicap_situation',
              value: currentAccompaniment.accompaniment_outplacement_individual_handicap_situation,
              options: optionsYesNo,
            }}
            situations={{
              id: 'accompaniment_outplacement_individual_situations',
              value: currentAccompaniment.accompaniment_outplacement_individual_situations,
              options: optionsSitutation,
            }}
            readOnly={true}
            locale={AccompanimentCoSystemLocale}
          />

          <CurrentJobCard
            title={'Poste actuel'}
            company={{
              id: 'accompaniment_outplacement_individual_job_company',
              value: currentAccompaniment.accompaniment_outplacement_individual_job_company,
            }}
            startDate={{
              id: 'accompaniment_outplacement_individual_start_job_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_start_job_date,
            }}
            location={{
              id: 'accompaniment_outplacement_individual_job_location',
              value: currentAccompaniment.accompaniment_outplacement_individual_job_location,
            }}
            seniority={{
              id: 'accompaniment_outplacement_individual_job_seniority',
              value: currentAccompaniment.accompaniment_outplacement_individual_job_seniority,
            }}
            remuneration={{
              id: 'accompaniment_outplacement_individual_job_remuneration',
              value: currentAccompaniment.accompaniment_outplacement_individual_job_remuneration,
            }}
            readOnly={true}
            locale={AccompanimentCoJobLocale}
          />

          <SolutionCard
            title={'Solution identifiée'}
            newJob={{
              id: 'accompaniment_outplacement_individual_new_job',
              value: currentAccompaniment.accompaniment_outplacement_individual_new_job,
            }}
            newRemuneration={{
              id: 'accompaniment_outplacement_individual_new_remuneration',
              value: currentAccompaniment.accompaniment_outplacement_individual_new_remuneration,
            }}
            compensationRemuneration={{
              id: 'accompaniment_outplacement_individual_compensation_remuneration',
              value:
                currentAccompaniment.accompaniment_outplacement_individual_compensation_remuneration,
              options: optionsDifferentialRemuneration,
            }}
            newActivitySector={{
              id: 'accompaniment_outplacement_individual_new_activity_sector',
              value: currentAccompaniment.accompaniment_outplacement_individual_new_activity_sector,
              options: activityOptions,
            }}
            solutions={{
              id: 'accompaniment_outplacement_individual_solutions',
              value: currentAccompaniment.accompaniment_outplacement_individual_solutions,
              options: optionsSolutions,
            }}
            otherSolution={{
              id: 'accompaniment_outplacement_individual_other_solution',
              value: currentAccompaniment.accompaniment_outplacement_individual_other_solution,
            }}
            locale={AccompanimentCoSolutionLocale}
            readOnly={true}
          />

          <FollowupProjectCard
            title={'Suivi du projet'}
            numberOfRdv={{
              id: 'accompaniment_outplacement_individual_number_rdv',
              value: currentAccompaniment.accompaniment_outplacement_individual_number_rdv,
              options: optionsNumbers,
            }}
            numberOfJobOffer={{
              id: 'accompaniment_outplacement_individual_number_job_offers',
              value: currentAccompaniment.accompaniment_outplacement_individual_number_job_offers,
              options: optionsNumbers,
            }}
            numberOfWorkshop={{
              id: 'accompaniment_outplacement_individual_number_workshop',
              value: currentAccompaniment.accompaniment_outplacement_individual_number_workshop,
              options: optionsNumbers,
            }}
            locale={AccompanimentCoFollowupProjectLocale}
            readOnly={true}
          />
          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_outplacement_individual_formation_type',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_outplacement_individual_formation_name',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_outplacement_individual_opinion_consultant',
              value: currentAccompaniment.accompaniment_outplacement_individual_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_outplacement_individual_financing_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_outplacement_individual_formation_start_date',
              value:
                currentAccompaniment.accompaniment_outplacement_individual_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_outplacement_individual_formation_end_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_outplacement_individual_cost_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_outplacement_individual_duration_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}

          />
          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_outplacement_individual_formation_type',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_outplacement_individual_formation_name',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_outplacement_individual_opinion_consultant',
              value: currentAccompaniment.accompaniment_outplacement_individual_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_outplacement_individual_financing_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_outplacement_individual_formation_start_date',
              value:
                currentAccompaniment.accompaniment_outplacement_individual_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_outplacement_individual_formation_end_date',
              value: currentAccompaniment.accompaniment_outplacement_individual_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_outplacement_individual_cost_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_outplacement_individual_duration_formation',
              value: currentAccompaniment.accompaniment_outplacement_individual_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}
            formationPrincipale={false}

          />
        </div>
      </>
    );
  };

  const renderVAE = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        vaeAvailability={{
          id: 'accompaniment_vae_availability',
          value: currentAccompaniment.accompaniment_vae_availability
            ? currentAccompaniment.accompaniment_vae_availability
            : 'within_work',
          options: vaeAvailabilities,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        group={{
          id: 'accompaniment_group',
          value: currentAccompaniment.accompaniment_group,
          options: [],
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        globalTime={{
          id: 'accompaniment_global_time',
          value: currentAccompaniment.accompaniment_global_time,
        }}
        homeOffice={{
          id: 'accompaniment_home_office',
          value: currentAccompaniment.accompaniment_home_office,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        vaeEligibilityDate={{
          id: 'accompaniment_eligibility_date',
          value: currentAccompaniment.accompaniment_eligibility_date,
        }}
        bookletDate={{
          id: 'accompaniment_booklet_date',
          value: currentAccompaniment.accompaniment_booklet_date,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        firstAppointmentDate={{
          id: 'accompaniment_first_appointment_date',
          value: currentAccompaniment.accompaniment_first_appointment_date,
        }}
        situationFollowUp={{
          id: 'accompaniment_appraisal_situation_follow_up',
          value: currentAccompaniment.accompaniment_appraisal_situation_follow_up
            ? currentAccompaniment.accompaniment_appraisal_situation_follow_up
            : 'training_or_training_project',
          options: situationFollowUpList,
        }}
        readOnly={true}
      />
    );
  };

  const renderCoaching = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        firstAppointmentDate={{
          id: 'accompaniment_first_appointment_date',
          value: currentAccompaniment.accompaniment_first_appointment_date,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        situationFollowUp={{
          id: 'accompaniment_appraisal_situation_follow_up',
          value: currentAccompaniment.accompaniment_appraisal_situation_follow_up
            ? currentAccompaniment.accompaniment_appraisal_situation_follow_up
            : 'training_or_training_project',
          options: situationFollowUpList,
        }}
        readOnly={true}
      />
    );
  };

  const renderAccompanimentCollective = () => {
    return (
      <>
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: currentAccompaniment.accompaniment_type,
            options: accompanimentTypes,
          }}
          accompanimentCollectiveType={{
            id: 'accompaniment_collective_type',
            value: currentAccompaniment.accompaniment_collective_type,
            options: typeCollectiveAccompaniment,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
          }}
          readOnly={true}
        />
        <div className="beneficiary-accompaniment-collective">
        <AccompCoSystemCard
            title={'Dispositif préalable'}
            entrySystem={{
              id: 'accompaniment_co_system_prior',
              value: currentAccompaniment.accompaniment_co_system_prior,
            }}
            joinDate={{
              id: 'accompaniment_co_join_date_prior',
              value: currentAccompaniment.accompaniment_co_join_date_prior,
            }}
            duration={{
              id: 'accompaniment_co_duration_prior',
              value: currentAccompaniment.accompaniment_co_duration_prior,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_co_signed_membership_charter_prior',
              value: currentAccompaniment.accompaniment_co_signed_membership_charter_prior,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_co_signed_eeo_prior',
              value: currentAccompaniment.accompaniment_co_signed_eeo_prior,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_co_start_date_prior',
              value: currentAccompaniment.accompaniment_co_start_date_prior,
            }}
            endDate={{
              id: 'accompaniment_co_end_date_prior',
              value: currentAccompaniment.accompaniment_co_end_date_prior,
            }}
            endRealDate={{
              id: 'accompaniment_co_real_end_date_prior',
              value: currentAccompaniment.accompaniment_co_real_end_date_prior,
            }}
            readOnly={true}
            situations={{
              id: 'accompaniment_co_situations_prior',
              value: currentAccompaniment.accompaniment_co_situations_prior,
              options: optionsSitutation,
            }}
            handicapSituation={{
              id: 'accompaniment_co_handicap_situation_prior',
              value: currentAccompaniment.accompaniment_co_handicap_situation_prior,
              options: optionsYesNo,
            }}
            locale={AccompanimentCoSystemLocale}
          />
          <AccompCoSystemCard
            title={'Dispositif'}
            entrySystem={{
              id: 'accompaniment_co_system',
              value: currentAccompaniment.accompaniment_co_system,
            }}
            joinDate={{
              id: 'accompaniment_co_join_date',
              value: currentAccompaniment.accompaniment_co_join_date,
            }}
            duration={{
              id: 'accompaniment_co_duration',
              value: currentAccompaniment.accompaniment_co_duration,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_co_signed_membership_charter',
              value: currentAccompaniment.accompaniment_co_signed_membership_charter,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_co_signed_eeo',
              value: currentAccompaniment.accompaniment_co_signed_eeo,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_co_start_date',
              value: currentAccompaniment.accompaniment_co_start_date,
            }}
            endDate={{
              id: 'accompaniment_co_end_date',
              value: currentAccompaniment.accompaniment_co_end_date,
            }}
            endRealDate={{
              id: 'accompaniment_co_real_end_date',
              value: currentAccompaniment.accompaniment_co_real_end_date,
            }}
            readOnly={true}
            situations={{
              id: 'accompaniment_co_situations',
              value: currentAccompaniment.accompaniment_co_situations,
              options: optionsSitutation,
            }}
            handicapSituation={{
              id: 'accompaniment_co_handicap_situation',
              value: currentAccompaniment.accompaniment_co_handicap_situation,
              options: optionsYesNo,
            }}
            locale={AccompanimentCoSystemLocale}
          />

          <CurrentJobCard
            title={'Poste actuel'}
            company={{
              id: 'accompaniment_co_job_company',
              value: currentAccompaniment.accompaniment_co_job_company,
            }}
            startDate={{
              id: 'accompaniment_co_start_job_date',
              value: currentAccompaniment.accompaniment_co_start_job_date,
            }}
            location={{
              id: 'accompaniment_co_job_location',
              value: currentAccompaniment.accompaniment_co_job_location,
            }}
            seniority={{
              id: 'accompaniment_co_job_seniority',
              value: currentAccompaniment.accompaniment_co_job_seniority,
            }}
            remuneration={{
              id: 'accompaniment_co_job_remuneration',
              value: currentAccompaniment.accompaniment_co_job_remuneration,
            }}
            readOnly={true}
            locale={AccompanimentCoJobLocale}
          />
          <SolutionCard
            title={'Solution identifiée'}
            newJob={{
              id: 'accompaniment_co_new_job',
              value: currentAccompaniment.accompaniment_co_new_job,
            }}
            newRemuneration={{
              id: 'accompaniment_co_new_remuneration',
              value: currentAccompaniment.accompaniment_co_new_remuneration,
            }}
            compensationRemuneration={{
              id: 'accompaniment_co_compensation_remuneration',
              value: currentAccompaniment.accompaniment_co_compensation_remuneration,
              options: optionsDifferentialRemuneration,
            }}
            newActivitySector={{
              id: 'accompaniment_co_new_activity_sector',
              value: currentAccompaniment.accompaniment_co_new_activity_sector,
              options: activityOptions,
            }}
            solutions={{
              id: 'accompaniment_co_solutions',
              value: currentAccompaniment.accompaniment_co_solutions,
              options: optionsSolutions,
            }}
            otherSolution={{
              id: 'accompaniment_co_other_solution',
              value: currentAccompaniment.accompaniment_co_other_solution,
            }}
            locale={AccompanimentCoSolutionLocale}
            readOnly={true}
          />
          <FollowupProjectCard
            title={'Suivi du projet'}
            numberOfRdv={{
              id: 'accompaniment_co_number_rdv',
              value: currentAccompaniment.accompaniment_co_number_rdv,
              options: optionsNumbers,
            }}
            numberOfJobOffer={{
              id: 'accompaniment_co_number_job_offers',
              value: currentAccompaniment.accompaniment_co_number_job_offers,
              options: optionsNumbers,
            }}
            numberOfWorkshop={{
              id: 'accompaniment_co_number_workshop',
              value: currentAccompaniment.accompaniment_co_number_workshop,
              options: optionsNumbers,
            }}
            locale={AccompanimentCoFollowupProjectLocale}
            readOnly={true}
          />
          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_co_formation_type',
              value: currentAccompaniment.accompaniment_co_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_co_formation_name',
              value: currentAccompaniment.accompaniment_co_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_co_opinion_consultant',
              value: currentAccompaniment.accompaniment_co_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_co_financing_formation',
              value: currentAccompaniment.accompaniment_co_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_co_formation_start_date',
              value: currentAccompaniment.accompaniment_co_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_co_formation_end_date',
              value: currentAccompaniment.accompaniment_co_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_co_cost_formation',
              value: currentAccompaniment.accompaniment_co_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_co_duration_formation',
              value: currentAccompaniment.accompaniment_co_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}
          />
          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_co_formation_type',
              value: currentAccompaniment.accompaniment_co_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_co_formation_name',
              value: currentAccompaniment.accompaniment_co_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_co_opinion_consultant',
              value: currentAccompaniment.accompaniment_co_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_co_financing_formation',
              value: currentAccompaniment.accompaniment_co_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_co_formation_start_date',
              value: currentAccompaniment.accompaniment_co_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_co_formation_end_date',
              value: currentAccompaniment.accompaniment_co_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_co_cost_formation',
              value: currentAccompaniment.accompaniment_co_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_co_duration_formation',
              value: currentAccompaniment.accompaniment_co_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}
            formationPrincipale={false}
          />
        </div>
      </>
    );
  };

  const renderOrientationReview = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        accompanimentSurveyCount={{
          id: 'accompaniment_survey_count',
          value: currentAccompaniment.accompaniment_survey_count,
        }}
        accompanimentSurveyType={{
          id: 'accompaniment_survey_type',
          value: currentAccompaniment.accompaniment_survey_type,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        globalTime={{
          id: 'accompaniment_global_time',
          value: currentAccompaniment.accompaniment_global_time,
        }}
        firstAppointmentDate={{
          id: 'accompaniment_first_appointment_date',
          value: currentAccompaniment.accompaniment_first_appointment_date,
        }}
        situationFollowUp={{
          id: 'accompaniment_appraisal_situation_follow_up',
          value: currentAccompaniment.accompaniment_appraisal_situation_follow_up
            ? currentAccompaniment.accompaniment_appraisal_situation_follow_up
            : ' ',
          options: situationFollowUpList,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        readOnly={true}
      />
    );
  };

  const renderBusinessCreation = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        globalTime={{
          id: 'accompaniment_global_time',
          value: currentAccompaniment.accompaniment_global_time,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        firstAppointmentDate={{
          id: 'accompaniment_first_appointment_date',
          value: currentAccompaniment.accompaniment_first_appointment_date,
        }}
        readOnly={true}
      />
    );
  };

  const renderCollectiveScheme = () => {
    return (
      <>
        <AccompEditionCard
          readOnly={true}
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: currentAccompaniment.accompaniment_type,
            options: accompanimentTypes,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
          }}
        />
        <div className="beneficiary-collective-scheme">
        <AccompCoSystemCard
            title={'Dispositif préalable'}
            entrySystem={{
              id: 'accompaniment_co_system_prior',
              value: currentAccompaniment.accompaniment_co_system_prior,
            }}
            joinDate={{
              id: 'accompaniment_co_join_date_prior',
              value: currentAccompaniment.accompaniment_co_join_date_prior,
            }}
            duration={{
              id: 'accompaniment_co_duration_prior',
              value: currentAccompaniment.accompaniment_co_duration_prior,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_co_signed_membership_charter_prior',
              value: currentAccompaniment.accompaniment_co_signed_membership_charter_prior,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_co_signed_eeo_prior',
              value: currentAccompaniment.accompaniment_co_signed_eeo_prior,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_co_start_date_prior',
              value: currentAccompaniment.accompaniment_co_start_date_prior,
            }}
            endDate={{
              id: 'accompaniment_co_end_date_prior',
              value: currentAccompaniment.accompaniment_co_end_date_prior,
            }}
            endRealDate={{
              id: 'accompaniment_co_real_end_date_prior',
              value: currentAccompaniment.accompaniment_co_real_end_date_prior,
            }}
            readOnly={true}
            situations={{
              id: 'accompaniment_co_situations_prior',
              value: currentAccompaniment.accompaniment_co_situations_prior,
              options: optionsSitutation,
            }}
            handicapSituation={{
              id: 'accompaniment_co_handicap_situation_prior',
              value: currentAccompaniment.accompaniment_co_handicap_situation_prior,
              options: optionsYesNo,
            }}
            
            locale={AccompanimentCoSystemLocale}
          />
          <AccompCoSystemCard
            title={'Dispositif'}
            entrySystem={{
              id: 'accompaniment_collectif_scheme_system',
              value: currentAccompaniment.accompaniment_collectif_scheme_system,
            }}
            joinDate={{
              id: 'accompaniment_collectif_scheme_join_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_join_date,
            }}
            duration={{
              id: 'accompaniment_collectif_scheme_duration',
              value: currentAccompaniment.accompaniment_collectif_scheme_duration,
            }}
            signedMembershipCharter={{
              id: 'accompaniment_collectif_scheme_signed_membership_charter',
              value: currentAccompaniment.accompaniment_collectif_scheme_signed_membership_charter,
              options: optionsYesNo,
            }}
            signedEEO={{
              id: 'accompaniment_collectif_scheme_signed_eeo',
              value: currentAccompaniment.accompaniment_collectif_scheme_signed_eeo,
              options: optionsYesNo,
            }}
            startDate={{
              id: 'accompaniment_collectif_scheme_start_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_start_date,
            }}
            endRealDate={{
              id: 'accompaniment_collectif_scheme_real_end_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_real_end_date,
            }}
            endDate={{
              id: 'accompaniment_collectif_scheme_end_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_end_date,
            }}
            situations={{
              id: 'accompaniment_collectif_scheme_situations',
              value: currentAccompaniment.accompaniment_collectif_scheme_situations,
              options: optionsSitutation,
            }}
            handicapSituation={{
              id: 'accompaniment_collectif_scheme_handicap_situation',
              value: currentAccompaniment.accompaniment_collectif_scheme_handicap_situation,
              options: optionsYesNo,
            }}
            readOnly={true}
            locale={AccompanimentCoSystemLocale}
          />

          <CurrentJobCard
            title={'Poste actuel'}
            company={{
              id: 'accompaniment_collectif_scheme_job_company',
              value: currentAccompaniment.accompaniment_collectif_scheme_job_company,
            }}
            startDate={{
              id: 'accompaniment_collectif_scheme_start_job_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_start_job_date,
            }}
            location={{
              id: 'accompaniment_collectif_scheme_job_location',
              value: currentAccompaniment.accompaniment_collectif_scheme_job_location,
            }}
            seniority={{
              id: 'accompaniment_collectif_scheme_job_seniority',
              value: currentAccompaniment.accompaniment_collectif_scheme_job_seniority,
            }}
            remuneration={{
              id: 'accompaniment_collectif_scheme_job_remuneration',
              value: currentAccompaniment.accompaniment_collectif_scheme_job_remuneration,
            }}
            readOnly={true}
            locale={AccompanimentCoJobLocale}
          />

          <SolutionCard
            title={'Solution identifiée'}
            newJob={{
              id: 'accompaniment_collectif_scheme_new_job',
              value: currentAccompaniment.accompaniment_collectif_scheme_new_job,
            }}
            newRemuneration={{
              id: 'accompaniment_collectif_scheme_new_remuneration',
              value: currentAccompaniment.accompaniment_collectif_scheme_new_remuneration,
            }}
            compensationRemuneration={{
              id: 'accompaniment_collectif_scheme_compensation_remuneration',
              value: currentAccompaniment.accompaniment_collectif_scheme_compensation_remuneration,
              options: optionsDifferentialRemuneration,
            }}
            newActivitySector={{
              id: 'accompaniment_collectif_scheme_new_activity_sector',
              value: currentAccompaniment.accompaniment_collectif_scheme_new_activity_sector,
              options: activityOptions,
            }}
            solutions={{
              id: 'accompaniment_collectif_scheme_solutions',
              value: currentAccompaniment.accompaniment_collectif_scheme_solutions,
              options: optionsSolutions,
            }}
            otherSolution={{
              id: 'accompaniment_collectif_scheme_other_solution',
              value: currentAccompaniment.accompaniment_collectif_scheme_other_solution,
            }}
            locale={AccompanimentCoSolutionLocale}
            readOnly={true}
          />

          <FollowupProjectCard
            title={'Suivi du projet'}
            numberOfRdv={{
              id: 'accompaniment_collectif_scheme_number_rdv',
              value: currentAccompaniment.accompaniment_collectif_scheme_number_rdv,
              options: optionsNumbers,
            }}
            numberOfJobOffer={{
              id: 'accompaniment_collectif_scheme_number_job_offers',
              value: currentAccompaniment.accompaniment_collectif_scheme_number_job_offers,
              options: optionsNumbers,
            }}
            numberOfWorkshop={{
              id: 'accompaniment_collectif_scheme_number_workshop',
              value: currentAccompaniment.accompaniment_collectif_scheme_number_workshop,
              options: optionsNumbers,
            }}
            locale={AccompanimentCoFollowupProjectLocale}
            readOnly={true}
          />

          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_collectif_scheme_formation_type',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_collectif_scheme_formation_name',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_collectif_scheme_opinion_consultant',
              value: currentAccompaniment.accompaniment_collectif_scheme_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_collectif_scheme_financing_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_collectif_scheme_formation_start_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_collectif_scheme_formation_end_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_collectif_scheme_cost_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_collectif_scheme_duration_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}
          />
          <FormationCard
            title={'Formations'}
            formationType={{
              id: 'accompaniment_collectif_scheme_formation_type',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_type,
              options: optionsTypeFormation,
            }}
            nameFormation={{
              id: 'accompaniment_collectif_scheme_formation_name',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_name,
            }}
            opinionConsultant={{
              id: 'accompaniment_collectif_scheme_opinion_consultant',
              value: currentAccompaniment.accompaniment_collectif_scheme_opinion_consultant,
              options: optionsOpinionConsultant,
            }}
            financingFormation={{
              id: 'accompaniment_collectif_scheme_financing_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_financing_formation,
              options: optionsFinancing,
            }}
            startDate={{
              id: 'accompaniment_collectif_scheme_formation_start_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_start_date,
            }}
            endDate={{
              id: 'accompaniment_collectif_scheme_formation_end_date',
              value: currentAccompaniment.accompaniment_collectif_scheme_formation_end_date,
            }}
            costFormation={{
              id: 'accompaniment_collectif_scheme_cost_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_cost_formation,
            }}
            durationFormation={{
              id: 'accompaniment_collectif_scheme_duration_formation',
              value: currentAccompaniment.accompaniment_collectif_scheme_duration_formation,
            }}
            formationTitle={{
              id: 'accompaniment_formation_title',
              value: currentAccompaniment.accompaniment_formation_title,
            }}
            locale={AccompCoFormationLocale}
            readOnly={true}
            formationsData={formations}
            formationPrincipale={false}
          />
        </div>
      </>
    );
  };

  const renderFormation = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        globalTime={{
          id: 'accompaniment_global_time',
          value: currentAccompaniment.accompaniment_global_time,
        }}
        formation={{
          id: 'accompaniment_formation',
          value: currentAccompaniment.accompaniment_formation ? currentAccompaniment.accompaniment_formation : 'intra',
          options: formationList,
        }}
        formationTitle={{
          id: 'accompaniment_formation_title',
          value: currentAccompaniment.accompaniment_formation_title,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        readOnly={true}
      />
    );
  };

  const renderOther = () => {
    return (
      <AccompEditionCard
        title={'Accompagnement du talent'}
        locale={AccompanimentTypeLocale}
        accompanimentType={{
          id: 'accompaniment_type',
          value: currentAccompaniment.accompaniment_type,
          options: accompanimentTypes,
        }}
        startDate={{
          id: 'accompaniment_start_date',
          value: currentAccompaniment.accompaniment_start_date,
        }}
        endDate={{
          id: 'accompaniment_end_date',
          value: currentAccompaniment.accompaniment_end_date,
        }}
        otherAccompaniment={{
          id: 'accompaniment_other_name',
          value: currentAccompaniment.accompaniment_other_name,
        }}
        HandicapAmendmentRequirement={{
          id: 'accompaniment_handicap_amendment_requirements',
          value: currentAccompaniment.accompaniment_handicap_amendment_requirements,
        }}
        synchronousTime={{
          id: 'accompaniment_synchronous_time',
          value: currentAccompaniment.accompaniment_synchronous_time,
        }}
        asynchronousTime={{
          id: 'accompaniment_asynchronous_time',
          value: currentAccompaniment.accompaniment_asynchronous_time,
        }}
        globalTime={{
          id: 'accompaniment_global_time',
          value: currentAccompaniment.accompaniment_global_time,
        }}
        firstAppointmentDate={{
          id: 'accompaniment_first_appointment_date',
          value: currentAccompaniment.accompaniment_first_appointment_date,
        }}
        situationFollowUp={{
          id: 'accompaniment_appraisal_situation_follow_up',
          value: currentAccompaniment.accompaniment_appraisal_situation_follow_up
            ? currentAccompaniment.accompaniment_appraisal_situation_follow_up
            : 'training_or_training_project',
          options: situationFollowUpList,
        }}
        terms={{
          id: 'accompaniment_terms',
          value: currentAccompaniment.accompaniment_terms
            ? currentAccompaniment.accompaniment_terms
            : 'face_to_face',
          options: termsList,
        }}
        readOnly={true}
      />
    );
  };

  if (currentAccompaniment) {
    return (
      <div className="beneficiary-accompaniment">
        {currentAccompaniment.accompaniment_type === 'appraisal' && renderAppraisalAccompaniment()}
        {currentAccompaniment.accompaniment_type === 'outplacement_individual' &&
          renderOutplacementCollective()}
        {currentAccompaniment.accompaniment_type === 'vae' && renderVAE()}
        {currentAccompaniment.accompaniment_type === 'coaching' && renderCoaching()}
        {currentAccompaniment.accompaniment_type === 'collective_accompaniment' &&
          renderAccompanimentCollective()}
        {currentAccompaniment.accompaniment_type === 'orientation_review' &&
          renderOrientationReview()}
        {currentAccompaniment.accompaniment_type === 'business_creation' &&
          renderBusinessCreation()}
        {currentAccompaniment.accompaniment_type === 'collective_scheme' &&
          renderCollectiveScheme()}
        {currentAccompaniment.accompaniment_type === 'formation' && renderFormation()}
        {currentAccompaniment.accompaniment_type === 'other' && renderOther()}
        {/* <FinancialEditionCard
          financerState={{
            id: 'financer_state',
            value: financement.financer_state,
            options: financerStateList,
          }}
          financerStateColor={currentAccompaniment.financer_state_color}
          financerType={{
            id: 'financer_type',
            value: financement.financer_type,
            options: financerTypeList,
          }}
          financerOpacifType={{
            id: 'financer_opacif_type',
            value: financement.financer_opacif_type,
            options: financerOpacifTypeList,
          }}
          financerName={{
            id: 'financer_name',
            value: financement.financer_name,
          }}
          financerSupportRequestFile={{
            id: 'financer_support_request_file',
            value: financement.financer_support_request_file ? financement.financer_support_request_file : '',
          }}
          financerSupportAgreementFile={financement.financer_support_agreement_file ?? ''}
          financialHT={{
            id: 'financial_ht',
            value: financement.financial_ht,
          }}
          financialTVA={{
            id: 'financial_tva',
            value: financement.financial_tva,
            options: financialTVAList,
          }}
          totalTVA={currentAccompaniment.total_tva}
          totalTTC={currentAccompaniment.total_ttc}
          locale={financerTypeLocale}
          readOnly={true}
          
        /> */}
      </div>
    );
  } else return null;
}

const EnhancedBeneficiaryAccompaniment = reduxForm({
  form: 'testt',
  asyncChangeFields: ['title', 'date', 'rdv_at', 'rdv_to'],
  asyncValidate: (values) =>
    asyncValidate(values, {
      title: { rule: 'required' },
      date: { rule: 'required|dateWithFormat:DD/MM/YYYY|dateAfterNow:DD/MM/YYYY' },
      rdv_at: { rule: 'required' },
      rdv_to: {
        rule: `required|above:${values.rdv_at}`,
        message: 'Erreur',
      },
    }),
})(BeneficiaryAccompaniment);

export default EnhancedBeneficiaryAccompaniment;
