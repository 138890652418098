import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconCardTitle from '../../molecules/IconCardTitle/IconCardTitle';
import ComboField from '../../molecules/ComboField/ComboField';
import InputField from '../../molecules/InputField/InputField';
import DateField from '../../molecules/DateField/DateField';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import { Modal } from '../../atoms/Modal/Modal';
import Icon from '../../atoms/Icon/Icon';
import moment from 'moment';
import './FormationCard.scss';
import { use } from 'react';

export const FormationCard = ({
  title,
  locale,
  readOnly,
  onChange,
  formationType,
  nameFormation,
  opinionConsultant,
  financingFormation,
  startDate,
  endDate,
  costFormation,
  durationFormation,
  formationsData,
  updateFormation,
  formationPrincipale = true,
}) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [currentIndexFormation, setCurrentIndexFormation] = useState(-1);
  const [currentFormation, setCurrentFormation] = useState({
    type: '',
    name: '',
    opinion_consultant: '',
    financing: '',
    start_date: null,
    end_date: null,
    duration: '',
    cost: '',
  });

  const openFormationEditModal = (index) => {
    setCurrentIndexFormation(index);
    setIsOpenEdit(true);
  };

  const closeFormationEditModal = () => {
    setCurrentIndexFormation(-1);
    setIsOpenEdit(false);
  };

  const deleteFormation = (index) => {
    const newFormations = [...formationsData];
    newFormations.splice(index, 1);
    updateFormation(newFormations);
  };

  useEffect(() => {
    if (formationsData && formationsData?.length > 0) {
      setCurrentFormation(formationsData[currentIndexFormation]);
    }
  }, [currentIndexFormation, formationsData, isOpenNew]);

  const renderForm = () => {
    return (
      <div className="accomp-collective-formation-card-content">
        <ComboField
          title={formationTypeLabel}
          name={'type'}
          key={'type'}
          value={isOpenNew ? null : currentFormation?.type}
          options={formationType.options}
          onChange={(value) => {
            const newFormations = [...formationsData];
            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              type: value.value,
            };
            updateFormation(newFormations);
          }}
          readOnly={readOnly}
        />
        <InputField
          title={nameFormationLabel}
          name={'name'}
          value={isOpenNew ? null : currentFormation?.name}
          onChange={(value) => {
            const newFormations = [...formationsData];
            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              name: value.value,
            };
            updateFormation(newFormations);
          }}
          type="string"
          readonly={readOnly}
        />
        <ComboField
          title={opinionConsultantLabel}
          name={opinionConsultant.id}
          key={opinionConsultant.id}
          value={isOpenNew ? null : currentFormation?.opinion_consultant}
          options={opinionConsultant.options}
          onChange={(value) => {
            const newFormations = [...formationsData];
            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              opinion_consultant: value.value,
            };
            updateFormation(newFormations);
          }}
          readOnly={readOnly}
        />

        <ComboField
          title={financingLabel}
          name={financingFormation.id}
          key={financingFormation.id}
          value={isOpenNew ? null : currentFormation?.financing}
          options={financingFormation.options}
          onChange={(value) => {
            const newFormations = [...formationsData];
            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              financing: value.value,
            };
            updateFormation(newFormations);
          }}
          readOnly={readOnly}
        />
        <DateField
          title={startDateLabel}
          name={startDate.id}
          value={moment(currentFormation?.start_date, 'YYYY-MM-DD')}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={(value) => {
            const newFormations = [...formationsData];
            const momentDate = moment(value?.value, ['DD/MM/YYYY', 'YYYY-MM-DD HH:mm:ss'], true);

            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              start_date: momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null,
            };
            updateFormation(newFormations);
          }}
          locale={locale}
          readOnly={readOnly}
        />
        <DateField
          title={endDateLabel}
          name={endDate.id}
          value={moment(currentFormation?.end_date, 'YYYY-MM-DD')}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={(value) => {
            const newFormations = [...formationsData];
            const momentDate = moment(value?.value, ['DD/MM/YYYY', 'YYYY-MM-DD HH:mm:ss'], true);
            newFormations[currentIndexFormation] = {
              ...formationsData[currentIndexFormation],
              end_date: momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null,
            };
            updateFormation(newFormations);
          }}
          locale={locale}
          readOnly={readOnly}
        />

        <div className="accomp-collective-formation-card-content-flex ">
          <InputField
            title={durationLabel}
            name={durationFormation.id}
            value={isOpenNew ? null : currentFormation?.duration}
            onChange={(value) => {
              const newFormations = [...formationsData];
              newFormations[currentIndexFormation] = {
                ...formationsData[currentIndexFormation],
                duration: value.value,
              };
              updateFormation(newFormations);
            }}
            type="string"
            readonly={readOnly}
          />
          <span>H</span>
        </div>

        <div className="accomp-collective-formation-card-content-flex ">
          <InputField
            title={costLabel}
            name={costFormation.id}
            value={isOpenNew ? null : currentFormation?.cost}
            onChange={(value) => {
              const newFormations = [...formationsData];
              newFormations[currentIndexFormation] = {
                ...formationsData[currentIndexFormation],
                cost: value.value,
              };
              updateFormation(newFormations);
            }}
            type="string"
            readonly={readOnly}
          />
          <span>€</span>
        </div>
      </div>
    );
  };

  const renderPrincipaleForm = () => {
    return (
      <div className="accomp-collective-formation-card-content">
        <ComboField
          title={formationTypeLabel}
          name={formationType?.id}
          key={formationType?.id}
          value={isOpenNew ? null : formationType?.value}
          options={formationType.options}
          onChange={onChange}
          readOnly={readOnly}
        />
        <InputField
          title={nameFormationLabel}
          name={nameFormation?.id}
          value={isOpenNew ? null : nameFormation?.value}
          onChange={onChange}
          type="string"
          readonly={readOnly}
        />
        <ComboField
          title={opinionConsultantLabel}
          name={opinionConsultant.id}
          key={opinionConsultant.id}
          value={isOpenNew ? null : opinionConsultant?.value}
          options={opinionConsultant.options}
          onChange={onChange}
          readOnly={readOnly}
        />

        <ComboField
          title={financingLabel}
          name={financingFormation.id}
          key={financingFormation.id}
          value={isOpenNew ? null : financingFormation?.value}
          options={financingFormation.options}
          onChange={onChange}
          readOnly={readOnly}
        />
        <DateField
          title={startDateLabel}
          name={startDate.id}
          value={isOpenNew ? null : startDate?.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={onChange}
          locale={locale}
          readOnly={readOnly}
        />
        <DateField
          title={endDateLabel}
          name={endDate.id}
          value={isOpenNew ? null : endDate?.value}
          calendarLabels={{ dayShortLabels, monthLabels }}
          onChange={onChange}
          locale={locale}
          readOnly={readOnly}
        />

        <div className="accomp-collective-formation-card-content-flex ">
          <InputField
            title={durationLabel}
            name={durationFormation.id}
            value={isOpenNew ? null : durationFormation?.value}
            onChange={onChange}
            type="string"
            readonly={readOnly}
          />
          <span>H</span>
        </div>

        <div className="accomp-collective-formation-card-content-flex ">
          <InputField
            title={costLabel}
            name={costFormation.id}
            value={isOpenNew ? null : costFormation?.value}
            onChange={onChange}
            type="string"
            readonly={readOnly}
          />
          <span>€</span>
        </div>
      </div>
    );
  };

  const {
    formationTypeLabel,
    nameFormationLabel,
    opinionConsultantLabel,
    financingLabel,
    startDateLabel,
    endDateLabel,
    costLabel,
    durationLabel,
    monthLabels,
    dayShortLabels,
  } = locale;
  return (
    <div className="accomp-collective-formation-card">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconCardTitle icon="edit2" color="accent" label={!formationPrincipale ? title : 'Formation principale'} />
        {!readOnly && !formationPrincipale && (
          <div className="circle-button-wrapper">
            <CircleButton
              icon="plus"
              onClick={() => {
                setCurrentIndexFormation(formationsData.length);
                setIsOpenNew(true);
              }}
            />
          </div>
        )}
      </div>
      {!formationPrincipale && formationsData && (
        <div>
          {formationsData?.length > 0 ? (
            formationsData?.map((formation, i) => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}
                  key={i}
                >
                  {formation.name ?? 'Formation sans nom'}{' '}
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <CircleButton
                      icon={!readOnly ? Icon.icon.Edit : Icon.icon.Next}
                      onClick={() => {
                        openFormationEditModal(i);
                      }}
                    />
                    {!readOnly && (
                      <CircleButton
                        icon={Icon.icon.Trash}
                        onClick={() => {
                          deleteFormation(i);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div>Aucunes formations</div>
          )}
        </div>
      )}
      {formationPrincipale && renderPrincipaleForm()}

      <Modal
        onClosePopin={() => closeFormationEditModal()}
        openPopin={isOpenEdit}
        title={"Modification d'une formation"}
        content={renderForm()}
        cancelButton={!readOnly ? 'Annuler' : null}
        disabledActionButton={false}
        actionButton={!readOnly ? 'Valider' : 'Retour'}
        handleActionButton={() => {
          closeFormationEditModal();
        }}
        // loading={loading}
      />
      <Modal
        onClosePopin={() => setIsOpenNew(false)}
        openPopin={isOpenNew}
        title={"Création d'une formation"}
        content={renderForm()}
        cancelButton={'Annuler'}
        disabledActionButton={false}
        actionButton={'Valider'}
        handleActionButton={() => setIsOpenNew(false)}
        // loading={loading}
      />
    </div>
  );
};

FormationCard.propTypes = {
  title: PropTypes.string,
  formationType: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  nameFormation: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  opinionConsultant: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  financingFormation: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  startDate: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  endDate: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  costFormation: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
  durationFormation: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default FormationCard;
