import React, { useState } from 'react';

import { editSection } from '../../../../api/IAsynthesisApi';

import PrimaryButton from '../../../atoms/PrimaryButton/PrimaryButton';
import TextAreaEditor from '../../TextAreaEditor/TextAreaEditor';

import './EditSynthesisSectionPopin.scss';

const EditSynthesisPopin = ({ ...props }) => {
  const { synthesis, sectionId, onClose } = props;
  const text = synthesis.aiSynthesisSection.find(
    (section) => section.sectionId === sectionId,
  ).modifiedText;
  const baseText = synthesis.aiSynthesisSection.find(
    (section) => section.sectionId === sectionId,
  ).generatedText;
  const titleSection = synthesis.aiSynthesisSection.find(
    (section) => section.sectionId === sectionId,
  ).sectionCode;

  const [valueText, setValueText] = useState(text);

  /**
   * Handles the change event for a text input element.
   *
   * @param {Object} e - The event object from the input change event.
   * @param {Object} e.target - The target element of the event.
   * @param {string} e.target.value - The current value of the input element.
   */
  function onChangeText(text) {
    setValueText(text);
  }

  /**
   * Handles the save operation for updating a synthesis section.
   *
   * This function constructs an updated section object with the modified text
   * and sends it to the server using the `editSection` API call. Upon successful
   * response, it triggers the `onUpdate` callback with the updated AI synthesis data.
   * If the operation is successful, it closes the popin after a delay.
   * Logs an error message if the API call fails or if an exception occurs.
   */
  async function handleSave() {
    const { onUpdate } = props;
    try {
      const updatedSection = {
        modifiedText: valueText, // Nouvelle valeur
      };
      const response = await editSection(synthesis.id, sectionId, updatedSection);
      if (response) {
        onUpdate(response.ai_synthesis);
        onClose(false);
      } else {
        console.error("Erreur lors de l'envoi des données.");
      }
    } catch (error) {
      console.error('Une erreur est survenue :', error);
    }
  }

  /**
   * Handles the closing of the popin. If the text content has been modified,
   * prompts the user to confirm whether they want to save the changes. If the
   * user confirms, the changes are saved before closing. Otherwise, closes
   * the popin without saving.
   */
  async function handleClose() {
    const { onClose } = props;
    if (valueText !== text) {
      const confirm = window.confirm(
        'Le contenu de la section a été modifié. Voulez-vous sauvegarder ces changements ?',
      );
      if (!confirm) {
        return;
      }

      await handleSave();
    }
    onClose(false);
  }

  return (
    <>
      <div className="wrapper-title">
        <h1>{synthesis.name}</h1>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <PrimaryButton
            id={'resetButton'}
            label={'Réinitialiser'}
            disabled={valueText === baseText}
            onClick={() => setValueText(baseText)}
          />
          <PrimaryButton id={'closeButton'} label={'Fermer'} outline={true} onClick={handleClose} />
          <PrimaryButton
            id={'saveButton'}
            label={'Sauvegarder'}
            disabled={valueText === text}
            onClick={handleSave}
          />
        </div>
      </div>

      <div className="wrapper-edit-synthesis">
        <h3 className="label">{titleSection.charAt(0).toUpperCase() + titleSection.slice(1)}</h3>
        <div className="wrapper-textarea">
          <TextAreaEditor name={titleSection} value={valueText} onChange={onChangeText} />
        </div>

        <div className="wrapper-title">
          <h3 className="label">Base</h3>
        </div>

        <div className="wrapper-content">
          <div dangerouslySetInnerHTML={{ __html: baseText }} />
        </div>
      </div>
    </>
  );
};

export default EditSynthesisPopin;
