/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";

import { getAllConsultants } from "../../../api/ConsultantApi";
import InputField from "../../../components/molecules/InputField/InputField";
import BigCombo from "../../../components/molecules/BigCombo/BigCombo";
import PrimaryButton from "../../../components/atoms/PrimaryButton/PrimaryButton";
import CircleButton from "../../../components/atoms/CircleButton/CircleButton";
import { MemberGroupCard } from "../../../components/organisms/MemberGroupCard/MemberGroupCard";
import Icon from "../../../components/atoms/Icon/Icon";
import ParcoursAttribPopin from "../../../components/organisms/ParcoursAttribPopin/ParcoursAttribPopin";
import AttributeCoursePopin from "../../../containers/popins/AttributeCoursePopin/AttributeCoursePopin";

import './GroupContainer.scss';
import { getAllClients } from "../../../api/ClientsApi";
import { getMissionsByClient } from "../../../api/MissionsApi";
import { updateBeneficiary, getBeneficiary } from "../../../api/BeneficiaryAPI";
import { getConsultantsByTalents } from "../../../api/ConsultantApi";

import { useNavigation } from "../../../hooks/useNavigation";
export const GroupContainer = ({ user, group, members, deleteMember, showModalAddMember, handleInputChange, handleDropdownChange, reloadMembers = null }) => {
    const [consultants, setConsultants] = useState([]);
    const [clients, setClients] = useState([]);
    const [missions, setMissions] = useState([]);
    const [idClientActive, setIdClientActive] = useState();
    const { pathname, redirect } = useNavigation()
    const [membersState, setMembersState] = useState([]);
    const [attribOpen, setAttribOpen] = useState(false);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const { client_id } = group;
        mission_id && setIdClientActive(client_id);
    }, [group, mission_id])

    const setMembersF = async (members) => {
        const membersData = await Promise.all(members.map(async member => {
            const res = await getBeneficiary(member.id)
            const resConsultant = await getConsultantsByTalents(member.id)
            const isConsultantOfTalent = !!resConsultant.consultants?.find(consultant => consultant.user_id === user.id)
            return {...member, profile_id: res.id, isConsultantOfTalent: isConsultantOfTalent}
        }))
        if (membersData) setMembersState(membersData)
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) setMembersF(members)

        return () => {
            isMounted = false;
        }
    }, [members])

    const handleImageOnChange = (image) => {
        const groupImage = image.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
        }
        reader.readAsDataURL(groupImage);
    }

    useEffect(() => {
        getAllConsultants(user.office_id).then((response) => {
            const all_consultants = response.consultants;
            const array = [];
            all_consultants.map(c => {
                const consultant = { label: `${c.user.first_name} ${c.user.last_name}`, value: c.user_id }
                array.push(consultant)
            })
            setConsultants(array)
        })

        getAllClients(user.id).then((response) => {
            const all_clients = response.length <= 0 ? [] : response.clients.map((client) => {
                return {
                    value: client.id,
                    label: client.name
                }
            })
            const noClient = { label: "Aucun", value: null }

            all_clients.push(noClient)

            setClients(all_clients)
        })
    }, [user.id, user.office_id])

    useEffect(() => {
        if (idClientActive) {
            getMissionsByClient(idClientActive).then((response) => {
                if (response.missions) {

                    const all_missions = response?.missions.map(mission => {
                        return {
                            value: mission.id,
                            label: mission.name
                        }
                    })
                    setMissions(all_missions)
                }
                else {
                    setMissions({ value: null, label: "Aucune mission" })
                }
            })
        }
        else setMissions([])
    }, [idClientActive])



    const handleDropdownClientChange = (dropdown) => {
        const { value } = dropdown;
        handleDropdownChange(dropdown);
        setIdClientActive(value.value)

    }

    const handleViewTalent = (id) => {
        redirect(`/consultant/beneficiaries/${id}/profile`)
    }

    const renderAvatarGroup = (image) => {
        if (image) return <img src={image} alt="avatar-group" onClick={(image) => handleImageOnChange(image)} />

        return (
            <label>
                <Icon icon={'people'} />
                {/* <input type="file" onChange={(image) => handleImageOnChange(image)} /> */}
            </label>
        )
    }

    const { name, image, id, client_id, mission_id } = group;
    const defaultConsultant = consultants.filter(consultant => consultant.value === user.id);
    let defaultClient = null;
    let defaultMission = null;

    if (client_id) {
        defaultClient = clients.filter(client => client.value === group.client_id);
    }

    if (mission_id) {
        defaultMission = missions.filter(mission => mission.value === group.mission_id);
    }

    const statuses = [
        { key: '', value: 'Choisissez un statut' },
        { key: 'created', value: 'Prospect' },
        { key: 'wait_financer', value: 'En attente de financement' },
        { key: 'in_validation', value: 'En attente de validation' },
        { key: 'in_progress', value: 'En cours' },
        { key: 'follow_up', value: 'Suivi professionel' },
        { key: 'archived', value: 'Historique' },
    ];
    const options = statuses.map((item) => ({
        value: item.key,
        label: item.value,
    }));

    const changeStatusUpdateBeneficiary = async (id, status) => {
        const response = await getBeneficiary(id);
        if (response) {
            const data = {
                status: status,
                gender: response.gender,
                birth_date: response.birth_date,
                diploma_name: response.diploma_name,
                address: response.address,
                zip_code: response.zip_code,
                city: response.city,
                phone_home: response.phone_home,
                phone_mobile: response.phone_mobile,
                cursus_formation: response.cursus_formation,
                cursus_professional_situation: response.cursus_professional_situation,
                cursus_socio_professional_category: response.cursus_socio_professional_category,
                source: response.source,
                first_name: response.user.first_name,
                last_name: response.user.last_name,
                email: response.user.email,
                image_url: response.user.image_url,
                user_id: response.user.id
            }
            await updateBeneficiary(data);
            reloadMembers();
        }
        return response;
    }

    const getNewCourse = (newCourse) => {
        if (courses) {
            setCourses([...courses, newCourse])

        }
        else {
            setCourses([newCourse])
        }
    };

    const handleAttribPopin = () => {
        setAttribOpen(open => !open);
    }

    const renderAttribPopin = () => {
        if (!attribOpen) {
            return null
        }

        return <AttributeCoursePopin open beneficiaries={membersState} onClose={handleAttribPopin} newCourse={getNewCourse} />
    }

    const handleChangeStatus = (e) => {
        members.forEach(element => {
            changeStatusUpdateBeneficiary(element.id, e.value.value)
        });
    }
    return (
        <div className="group-content">
            <div className="group-presentation">
                <div className="group-presentation-card">
                    <h2>Présentation du groupe</h2>
                    <div className="presentation-card-container">
                        {renderAvatarGroup(image)}
                        <InputField title={"Nom du groupe"} name={"name"} value={name ? name : ""} required onChange={handleInputChange} />
                    </div>
                    <BigCombo title={"Chef(fe) de projet"} id={"user_id"} options={defaultConsultant} value={defaultConsultant[0]} onChange={handleDropdownChange} />
                    <div className="presentation-card-container">
                        <BigCombo title={"Clients"} id={"client_id"} options={clients ? clients : []} value={defaultClient ? defaultClient[0] : null} onChange={handleDropdownClientChange} />
                    </div>
                    <div className="presentation-card-container">
                        <BigCombo title={"Missions"} id={"mission_id"} options={missions ? missions : []} value={defaultMission ? defaultMission[0] : null} onChange={handleDropdownChange} />
                    </div>
                </div>
            </div>
            {id &&
                <div className="group-members">
                    {/* <div className="statuses_combo">
            <ComboField
              name="status"
              title={'Etat actuel :'}
              options={options}
              onChange={handleBeneficiaryStatusChange}
              value={beneficiaryStatus || 'created'}
              disabled={!isPrimary() || beneficiaryStatus === '' || /\/courses/.test(pathname)}
            />
          </div> */}
                    <BigCombo title={"Changer le statut de tous les membres du groupe"} id={"status"} options={options} value={''} onChange={handleChangeStatus} />
                    <PrimaryButton label={"Attribuer un parcours"} onClick={() => setAttribOpen(true)} />
                    <div className="group-members-presentation" style={{ paddingTop: "10px" }}>
                        {members.length > 0 ? <h2>Membres du groupe ({members.length})</h2> : <h2>Aucun membre ajouté</h2>}
                        <CircleButton icon={"plus"} onClick={showModalAddMember} />
                    </div>
                    <div className="all-members">
                        {membersState && membersState.map((member, index) => (
                            <MemberGroupCard member={member} deleteMember={deleteMember} key={member.id} onClick={() => handleViewTalent(member.id)} isConsultantOfTalent={member.isConsultantOfTalent} />
                        ))}
                    </div>
                    {renderAttribPopin()}

                </div>
            }
        </div>
    )
}