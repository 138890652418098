/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import EmptyCard from '../../components/molecules/EmptyCard/EmptyCard'
import SectionTitle from '../../components/molecules/SectionTitle/SectionTitle'
import ConsultantCard from '../../components/organisms/ConsultantCard/ConsultantCard'
import DisabledConsultantCard from '../../components/organisms/DisabledConsultantCard/DisabledConsultantCard'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import Page from '../../containers/global/Page/Page'
import ManagerConsultantActivate from '../../containers/manager/ManagerConsultantActivate/ManagerConsultantActivate'
import ManagerConsultantArchive from '../../containers/manager/ManagerConsultantArchive/ManagerConsultantArchive'
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux'
import { actions as OfficeActions, selectors as CabinetSelectors } from '../../redux/OfficeRedux'
import './ManagerConsultantsPage.scss';
import { getOfficeInfos } from '../../api/CentralTestApi'
import { getAllConsultants } from '../../api/ConsultantApi'

const mapStateToProps = (state) => ({
  pathname: NavigationSelectors.pathname(state),
  office: CabinetSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
})

const ManagerConsultantsPage = ({ redirect, pathname, getOffice, office }) => {
  const [consultantToDelete, setConsultantToDelete] = useState(null);
  const [consultantToActivate, setConsultantToActivate] = useState(null);
  const [consultantV2, setConsultantV2] = useState([]);

  const noRemainingConsultantAccess = office && office.no_consultants_allocated >= office.no_consultants

  const handleGetConsultants = async () => {
    if (!office) return null;
    try {
      const consu = await getAllConsultants(office.id);
      setConsultantV2(consu.consultants);
    } catch (error) {
      console.error('Failed to fetch consultants:', error);
    }
  }

  // EN ATTENTE DE LA ROUTE CENTRAL TEST
  const handleGetOfficeInfos = async (officeId) => {
    const res = await getOfficeInfos(officeId);
  }

  useEffect(() => {
    handleGetConsultants();
    /*if (office) {
       handleGetOfficeInfos(office.id)
    }*/
  }, [office])

  const handleCreateConsultantClick = () => {
    redirect(`${pathname}/new`)
  }

  const handleEditConsultantClick = (id) => {
    redirect(`${pathname}/${id}`)
  }

  const handleToggleShowConfirmArchivePopin = (id) => {
    setConsultantToDelete(id)
  }

  const handleToggleShowConfirmActivatePopin = (id) => {
    setConsultantToActivate(id)
  }

  useEffect(() => {
    getOffice();
  }, [])

  const renderTabs = () => {
    const links = [{ id: 'consultants', label: "Consultants" }]
    return <LineMenu value={links[0].id} links={links} />
  }

  const renderArchivePopin = () => {
    if (!consultantToDelete) {
      return null
    }

    return (
      <ManagerConsultantArchive
        consultantId={consultantToDelete}
        onClose={() => handleToggleShowConfirmActivatePopin(null)}
      />
    )
  }

  const renderActivatePopin = () => {
    if (!consultantToActivate) {
      return null
    }

    return (
      <ManagerConsultantActivate
        consultantId={consultantToActivate}
        onClose={() => handleToggleShowConfirmActivatePopin(null)}
      />
    )
  }

  const renderCards = (consultants) => {
    const cards = consultants.map((consultant) => {
      return (
        <ConsultantCard
          key={consultant.id}
          id={consultant.id.toString()}
          onEdit={() => handleEditConsultantClick(consultant.user_id)}
          onDelete={() => handleToggleShowConfirmArchivePopin(consultant.id)}
          locale={{
            beneficiariesLabel: "Les talents",
            beneficiariesInStatus: "t('manager.beneficiaries_in_status')",
          }}
          office={office}
          consultant={consultant}
        />
      )
    })

    return cards.concat(<EmptyCard key={`emptyCard}`} onClick={handleCreateConsultantClick} disabled={noRemainingConsultantAccess} />)
  }

  const renderDisabledCards = (consultants) => {
    const cards = consultants.map((consultant) => {
      return (
        <DisabledConsultantCard
          key={consultant.user?.id}
          id={consultant.user?.id.toString()}
          firstName={consultant.user?.first_name}
          lastName={consultant.user?.last_name}
          email={consultant.user?.email}
          phone={consultant.user?.phone}
          avatar={consultant.user?.image_url}
          onActivate={() => handleToggleShowConfirmActivatePopin(consultant.id)}
          disabled={noRemainingConsultantAccess}
        />
      )
    })

    return cards
  }

  const renderConsultants = () => {
    if (!office) {
      return null
    }
    const enabledConsultants = []
    const disabledConsultants = []

    consultantV2 && consultantV2.forEach((consultant) => {
      if (consultant.disabled_at) {
        disabledConsultants.push(consultant)
      } else {
        enabledConsultants.push(consultant)
      }
    })

    const enabledConsultantsNode = (
      <>
        <div
          className="informationsNoConsultants"
          dangerouslySetInnerHTML={{
            __html: `Nombre d'accès consultant utilisés : ${office.no_consultants_allocated} / ${office.no_consultants}`
          }}
        />

        <div className="cards">{renderCards(enabledConsultants)}</div>
      </>
    )

    if (disabledConsultants.length > 0) {
      const disabledConsultantsNode = (
        <div className="cards">
          {renderDisabledCards(disabledConsultants)}
        </div>
      )

      return (
        <div className="consultants-wrapper">
          <SectionTitle label={"Comptes consultants activés"} />
          <div className="enabled-consultants">{enabledConsultantsNode}</div>
          <SectionTitle label={"Comptes consultants désactivés"} />
          <div className="disabled-consultants">{disabledConsultantsNode}</div>
        </div>
      )
    }

    return enabledConsultantsNode
  }

  const renderContent = () => {
    return (
      <>
        {renderConsultants()}
        {renderArchivePopin()}
        {renderActivatePopin()}
      </>
    )
  }

  const aside = <ManagerPageAside />

  const content = (
    <TabbedContentLayout header={renderTabs()} content={renderContent()} />
  )

  return (
    <div className="manager-consultants-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  )

}
export default connect(mapStateToProps, mapDispatchToProps)(Page(ManagerConsultantsPage))