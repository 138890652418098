const AccompanimentCoSystemLocale = {
    entrySystemLabel: "Date d'entrée dans le dispositif",
    joinDateLabel: "Date d'adhésion",
    durationLabel: "Durée",
    signedMembershipCharterLabel: "Charte d'adhésion signée",
    signedEEOLabel: "EEO signé",
    startDateLabel: "Date de début",
    endDateLabel: "Date de fin prévue",
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    dayShortLabels: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
    subtitle: "Projet",
    handicapSituationLabel: "Situation RQTH qui nécessite un aménagement"
}

const optionsYesNo = [
    { value: " ", label: "..." },
    {value: "no", label: "Non"},
    {value: "yes", label: "Oui"},

]
export {
    AccompanimentCoSystemLocale,
    optionsYesNo
}