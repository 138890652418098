import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LineMenuLink from '../../molecules/LineMenuLink/LineMenuLink'
import './LineMenu.scss'
import { selectors as UserSelectors } from "../../../redux/UserRedux";
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
})

class LineMenu extends Component {

  constructor(props) {
    super(props);
    this.navbarRef = React.createRef();
    this.isDragging = false;
    this.isClickAfterDrag = false;
    this.isDragStarted = false;
    this.startX = 0;
    this.scrollLeft = 0;
    this.state = {
      leftPosition: 0,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mouseleave', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mouseleave', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  addGrabbingClassToChildren = () => {
    if (!this.navbarRef.current) return;

    const navbarChildren = this.navbarRef.current.querySelectorAll('*');
    navbarChildren.forEach((child) => {
      child.classList.add('navbar-grabbing');
    });
  };

  removeGrabbingClassFromChildren = () => {
    if (!this.navbarRef.current) return;

    const navbarChildren = this.navbarRef.current.querySelectorAll('*');
    navbarChildren.forEach((child) => {
      child.classList.remove('navbar-grabbing');
    });
  };

  handleMouseDown = (e) => {
    if (this.navbarRef.current && this.navbarRef.current.contains(e.target)) {
      e.preventDefault();
      this.navbarRef.current.classList.add('navbar-grabbing');
      this.addGrabbingClassToChildren(); 
      this.isDragging = true;
      this.isClickAfterDrag = false;
      this.isDragStarted = false;
      this.startX = e.pageX - this.navbarRef.current.offsetLeft;
      this.scrollLeft = this.navbarRef.current.scrollLeft;
    }
  };

  handleMouseUp = (e) => {
    this.navbarRef.current.classList.remove('navbar-grabbing');
    this.removeGrabbingClassFromChildren();
    this.isDragging = false;
    if (!this.isClickAfterDrag && this.isDragStarted) {
      // Empêcher le clic automatique sur un lien après le drag
      e.preventDefault();
    }
    this.isClickAfterDrag = true;
    this.isDragStarted = false;
  };

  handleMouseMove = (e) => {
    if (!this.isDragging) return;
    e.preventDefault();
    this.isDragStarted = true;
    const x = e.pageX - this.navbarRef.current.offsetLeft;
    const walk = (x - this.startX) * 1.5; // Adjust scrolling speed as needed
    this.navbarRef.current.scrollLeft = this.scrollLeft - walk;
  };

  static propTypes = {
    value: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        countIndicator: PropTypes.number,
        label: PropTypes.string.isRequired,
        sublabel: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ).isRequired,
    withSublabel: PropTypes.bool,
    components: PropTypes.node,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    withSublabel: false,
    onChange: (id) => undefined,
  }

  handleNavBarClick = () => {
    // Empêcher le clic automatique sur un lien après le drag
    this.isClickAfterDrag = true;
  };

  onClickLink = (e, id) => {
    this.props.onChange(id)
  }

  render() {

    const { links, value, components, withSublabel } = this.props;
    const { leftPosition } = this.state;
    const menuLinks = links.map((link) => (
      <LineMenuLink
        key={link.id}
        withSublabel={withSublabel}
        id={link.id}
        isSelected={value === link.id}
        label={link.label}
        sublabel={link.sublabel}
        countIndicator={link.countIndicator}
        onClick={this.onClickLink}
        isAvailable={link.isAvailable ?? true}
        notAvailableText={link.notAvailableText ?? 'Contactez-nous pour connaître nos offres'}
      />
    ))

    return (
      <>
      <div className="line-menu-components">
        <div className="resources-link" onClick={this.handleNavBarClick} ref={this.navbarRef} style={{ left: leftPosition }}>{menuLinks}</div>
        {components && <div className="components">{components}</div>}
      </div>
      </>
    )
  }
}

export default connect(mapStateToProps, null)(LineMenu);