import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SharedNoteHeader from '../../molecules/SharedNoteHeader/SharedNoteHeader'
import TextAreaField from '../../molecules/TextAreaField/TextAreaField'
import './SharedNotes.scss'
class SharedNotes extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    photoUrl: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onHeaderClick: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    photoUrl: '',
    value: '',
    placeholder: '',
    editable: false,
    onChange: () => undefined,
  }

  handleHeaderClick = () => {
    const { id, onHeaderClick } = this.props
    onHeaderClick({ id })
  }

  handleChange = ({ id, value }) => {
    this.props.onChange({ id, value })
  }

  renderNotes = () => {
    const { id, editable, value, placeholder } = this.props

    if (editable) {
      return (
        <TextAreaField
          name={id}
          onChange={this.handleChange}
          value={value}
          placeholder={placeholder}
        />
      )
    }

    return <div className="notes">{value}</div>
  }

  render() {
    const { id, label, isExpanded, photoUrl } = this.props

    return (
      <div className="shared-notes">
        <SharedNoteHeader
          id={id}
          open={isExpanded}
          photoUrl={photoUrl}
          label={label}
          onClick={this.handleHeaderClick}
        />
        <div className={classnames('text-container', isExpanded && 'expanded')}>
          {this.renderNotes()}
        </div>
      </div>
    )
  }
}

export default SharedNotes
