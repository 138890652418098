import { Map } from 'immutable';
import { createAction, createReducer } from 'redux-act'

const initialState = Map({
    websocket: null,
    globalWebSocket: null,
})

export const actions = {
    setWebSocket: createAction('setWebSocket', (websocket) => ({ websocket })),
    getWebSocket: createAction('getWebSocket'),
}

export const reducer = createReducer(
    {
        [actions.setWebSocket]: (state, { websocket }) => state.merge({ websocket }),
    },
    initialState,
)

export const selectors = {
    setWebSocket: (state) => state.chat.get("websocket"),
}
