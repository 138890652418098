import React, { useCallback, useEffect, useRef, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { connect } from "react-redux";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";

const mapStateToProps = (state) => ({
    socket: ChatSelectors.setWebSocket(state),
})

function ControlledInput({ onUpdate, emojiSelected, conversationInfos, idUser, selectedFile, socket }) {

    const [value, setValue] = useState("");
    const [file, setFile] = useState(selectedFile);
    const inputRef = useRef();

    const sendMessage = useCallback((value, type = "text") => {

        let receiver = "";
        let author = "";
        let random_key = "";
        let author_name = "";

        if (parseInt(idUser) === conversationInfos.acquaintance_id) {
            receiver = conversationInfos.user_id;
            author = conversationInfos.acquaintance_id;
            author_name = conversationInfos.acquaintance_name;
        } else {
            receiver = conversationInfos.acquaintance_id;
            author = conversationInfos.user_id;
            author_name = conversationInfos.user_name;
        }

        if (type !== "text") {
            random_key = (Math.random() * (40000 - 20000) + 20000) + "_" + (Math.random() * (20000 - 10000) + 10000);
        } else {
            random_key = (Math.random() * (40000 - 20000) + 20000) + "_" + (Math.random() * (1000 - 500) + 300);
        }

        const message = {
            random_key: random_key,
            id_room: conversationInfos.id,
            room_key: conversationInfos.room_key,
            id_author: author,
            id_receiver: receiver,
            content: value,
            date_emitted: new Date().toLocaleString(),
            type: type,
            author_name: author_name
        };

        socket.send(JSON.stringify(message));

        if (file) {
            setFile(false);
        }

    }, [socket, conversationInfos, idUser, file]);

    const clear = () => {
        setValue("");
    }

    const handleChange = (e) => {
        setValue(e.target.value);
        onUpdate(e.target.value);
    }

    const handleKeyUp = (e) => {
        if (e.key === "Enter" && value) {
            sendMessage(value);
            clear();
        }
    }

    const handleClick = (e) => {
        if (value) {
            sendMessage(value);
            clear();
        }
    }

    useEffect(() => {
        setFile(selectedFile);
    }, [selectedFile])

    useEffect(() => {
        setValue(value => value + emojiSelected);
        inputRef.current.focus();

        if (file) {

            const reader = new FileReader();

            reader.onload = () => {

                const fileData = reader.result;

                sendMessage(fileData, "file");
                setFile(null);
            }

            reader.readAsDataURL(file);

        }

    }, [emojiSelected, sendMessage, file])

    return (
        <>
            <input type="text" className="chatbox-input" id="message" placeholder="Message..." name="message" value={value}
                onChange={handleChange} onKeyUp={handleKeyUp} ref={inputRef}
            />
            <button className="chatbox-sender" onClick={handleClick}></button>
        </>
    )
}

function FileInput({ onChange }) {

    const fileInputRef = useRef(null);

    const checkFile = (file) => {
        const maxSize = 15 * 1024 * 1024; // 15MB
        const allowedTypes = [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/svg+xml",
            "image/webp",
            "audio/ogg",
            "audio/mpeg",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/zip",
            "application/x-tar",
            "application/x-gzip",
            "application/x-bzip2",
            "application/x-7z-compressed",
            "application/x-rar-compressed",
            "application/x-tar-gz",
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.oasis.opendocument.presentation'
            // Ajouter d'autres types de fichiers si nécessaire
        ];
        if (file.size > maxSize) {
            alert("Le fichier dépasse la taille maximale autorisée (15MB).");
            return false;
        }

        // Vérification du type de fichier
        if (!allowedTypes.includes(file.type)) {
            alert("Le type de fichier n'est pas autorisé.");
            return false;
        }

        return true;
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file && checkFile(file)) {
            onChange(file);
        }
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
            />
            <button className="chatbox-attachment" onClick={() => fileInputRef.current.click()}></button>
        </>
    );
}

function ChatInput(props) {

    const emojiPicker = useRef(null);
    const inputMessage = useRef(null);
    const [hidden, setHidden] = useState(true);
    const [emoji, setEmoji] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const socket = props.socket;

    const handleClick = (e) => {

        if (emojiPicker.current && emojiPicker.current.contains(e.target)) {
            return;
        } else {
            setHidden(true);
        }
    }

    const openEmojiPicker = (e) => {
        if (hidden) {
            setHidden(false);
            document.addEventListener("click", handleClick);
        } else {
            setHidden(true);
            document.removeEventListener("click", handleClick);
        }
    }

    const selectEmoji = (e) => {
        setEmoji(e.emoji);
    }

    const handleFileChange = (file) => {
        // Faire quelque chose avec le fichier sélectionné
        setSelectedFile(file);
    };

    useEffect(() => {
        // Hide emojis
        setSelectedFile(null);
        setHidden(true);
        setEmoji("");
    }, []);

    useEffect(() => {
        if (emoji) {
            setEmoji("");
        }
    }, [emoji])

    let hide = {
        display: "none"
    }

    let show = {
        display: "block"
    }

    let categoriesOptions = [
        {
            category: "suggested",
            name: "Récemment utilisés"
        },
        {
            category: "smileys_people",
            name: "Smileys"
        },
        {
            category: "animals_nature",
            name: "Animaux & Nature"
        },
        {
            category: "food_drink",
            name: "Nourriture",
        },
        {
            category: "travel_places",
            name: "Voyages"
        },
        {
            category: "activities",
            name: "Activités"
        },
        {
            category: "objects",
            name: "Objets"
        },
        {
            category: "symbols",
            name: "Symboles"
        },
        {
            category: "flags",
            name: "Drapeaux"
        }
    ]

    return (
        <>
            <div className="chatbox-separator"></div>
            <div className="chatbox">
                <div className="chatbox-file-and-emoji">
                    {!hidden &&
                        <div className="emoji-picker" style={hidden ? hide : show}>
                            <EmojiPicker categories={categoriesOptions} searchDisabled={true} skinTonesDisabled={true} previewConfig={{ showPreview: false }} lazyLoadEmojis={true} onEmojiClick={selectEmoji} emojiStyle="twitter" />
                        </div>
                    }
                    <button className="chatbox-emoji-picker" ref={emojiPicker} onClick={openEmojiPicker}></button>
                    <FileInput onChange={handleFileChange} />
                </div>
                <ControlledInput
                    onUpdate={val => {
                        inputMessage.current = val
                    }}
                    emojiSelected={emoji}
                    conversationInfos={props.activeConversation}
                    idUser={props.idUser}
                    selectedFile={selectedFile}
                    socket={socket}
                />
            </div>
        </>
    )
}

export default connect(mapStateToProps, null)(ChatInput);