import React, { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import {
  getDirectives,
  addSynthesis,
  getAttachmentByBeneficiaryId,
} from '../../../api/IAsynthesisApi';

import Icon from '../../../components/atoms/Icon/Icon';
import AIAttachmentsSelect from '../../../components/molecules/AIAttachmentsSelect/AIAttachmentsSelect';
import TextAreaField from '../../../components/molecules/TextAreaField/TextAreaField';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';
import './AISynthesisCreation.scss';

const AISynthesisCreation = ({ beneficiary_id, consultant_id, setOpenModal, ...props }) => {
  const [stepIndex, setStepIndex] = useState(props.stepIndex || 0);
  const [valueStep, setValueSteps] = useState([]);
  const [attachments, setAttachments] = useState();
  const [isProcessing, setProcessing] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Asynchronously fetches AI directives and beneficiary attachments, formats the data,
   * and updates the state with the retrieved information.
   *
   * Retrieves AI directives using the `getDirectives` function and formats them into
   * a structured array. Adds a prompt for selecting relevant files. Fetches attachments
   * for a given beneficiary using `getAttachmentByBeneficiaryId` and updates the state
   * with the attachments and formatted directives.
   *
   * Handles errors by setting an error message and ensures loading state is updated
   * upon completion.
   *
   * @throws Will throw an error if the AI directives cannot be retrieved.
   */
  const fetchData = async () => {
    try {
      const response = await getDirectives();
      if (!response.ai_directives) {
        throw new Error('Erreur lors de la récupération des données.');
      }
      const data = response.ai_directives;
      const formattedData = data.map((item) => ({
        id: item.id,
        question: item.prompt,
        answer: '',
      }));

      formattedData.push({
        id: 4,
        question: 'Sélectionnez les fichiers pertinents pour cette synthèse : (3 fichiers maximum)',
        answer: [],
      });
      const attachmentsList = await getAttachmentByBeneficiaryId(beneficiary_id);
      setAttachments(attachmentsList.ai_attachments);

      setValueSteps(formattedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Updates the answer value of a specific step in the valueSteps state.
   *
   * @param {Object} e - The event object containing the id and value.
   * @param {number} e.id - The identifier of the step to update.
   * @param {string} e.value - The new answer value for the specified step.
   */
  const handleTextArea = (e) => {
    const { id, value } = e;
    setValueSteps((prevValue) => {
      const updatedValue = [...prevValue];
      updatedValue[id].answer = value;
      return updatedValue;
    });
  };

  /**
   * Handles the change event for a checkbox, updating the answer array
   * for the current step in the valueSteps state. If the checkbox is
   * selected, the ID is added to the answer array; if deselected, the
   * ID is removed. Logs an error if no step is found for the given index.
   *
   * @param {string} id - The identifier of the checkbox item to toggle.
   */
  const handleCheckboxChange = (id) => {
    setValueSteps((prevValue) => {
      const updatedValue = [...prevValue];
      if (!updatedValue[stepIndex]) {
        console.error(`Aucune étape trouvée pour l'index : ${stepIndex}`);
        return prevValue;
      }

      if (!Array.isArray(updatedValue[stepIndex].answer)) {
        updatedValue[stepIndex].answer = [];
      }

      const isSelected = updatedValue[stepIndex].answer.includes(id);
      if (isSelected) {
        updatedValue[stepIndex].answer = updatedValue[stepIndex].answer.filter(
          (attId) => attId !== id,
        );
      } else {
        updatedValue[stepIndex].answer.push(id);
      }

      return updatedValue;
    });
  };

  /**
   * Handles the transition to the next step in the synthesis creation process.
   * If the current step is not the last, it increments the step index.
   * Otherwise, it formats the data from the current steps and attachments,
   * and attempts to add a new synthesis via the API.
   * Sets processing state during the API call and handles any errors.
   * Closes the modal and stops processing upon successful completion.
   */
  const handleNextStep = async () => {
    if (stepIndex < valueStep?.length - 1) {
      setStepIndex((prev) => prev + 1);
    } else {
      setProcessing(true);

      const formattedData = {
        beneficiary_id: parseInt(beneficiary_id),
        consultant_id: parseInt(consultant_id),
        directives: valueStep
          .map((step, index) => {
            if (index < 3) {
              return {
                id_directive: step.id,
                answer: step.answer.trim(),
              };
            }
            return null;
          })
          .filter((item) => item !== null),
        attachments: valueStep[3].answer,
      };

      try {
        const response = await addSynthesis(formattedData);
        if (!response) {
          throw new Error(`Erreur serveur : ${response.status}`);
        }

        setTimeout(() => {
          handleClose();
          setProcessing(false);
        }, 1000);
      } catch (error) {
        setError(error.message);
        setProcessing(false);
      }
    }
  };

  const handleBackStep = () => {
    setStepIndex((prevIndex) => {
      if (prevIndex !== 0) {
        return prevIndex - 1;
      }
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <SpinnerCircular thickness={200} speed={150} color={Icon.color.Grey1} />
        <p>Chargement des questions...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>Erreur : {error}</p>
      </div>
    );
  }

  return (
    <div className="wrapper-form">
      {!isProcessing && (
        <>
          <div className="navigation">
            <button disabled={stepIndex === 0} onClick={handleBackStep}>
              <Icon icon={Icon.icon.ChevronLeft} />
            </button>
            <button
              disabled={!valueStep[stepIndex].answer || stepIndex === valueStep?.length - 1}
              onClick={handleNextStep}
            >
              <Icon icon={Icon.icon.ChevronRight} />
            </button>
          </div>

          <div className="stepsBar">
            {valueStep.map((_, index) => {
              const widthPercentage = 100 / valueStep.length;
              return (
                <div
                  key={index}
                  className={index === stepIndex ? 'current-step-bar' : 'step-bar'}
                  style={{ width: `${widthPercentage}%` }}
                ></div>
              );
            })}
          </div>

          <div className="form">
            <div className="title">
              <p>{stepIndex + 1}</p>
              <p>{valueStep[stepIndex].question}</p>
            </div>
            {stepIndex !== valueStep.length - 1 ? (
              <TextAreaField
                name={stepIndex.toString()}
                value={valueStep[stepIndex].answer}
                onChange={handleTextArea}
                placeholder={'Écrivez votre réponse...'}
              />
            ) : (
              <AIAttachmentsSelect
                attachments={attachments}
                selectedAttachments={valueStep[stepIndex]?.answer || []}
                onChange={handleCheckboxChange}
              />
            )}
          </div>

          <div className="modal-footer">
            <PrimaryButton label={'Annuler'} onClick={handleClose} cancel={true} id={'cancel'} />
            <PrimaryButton
              label={'Créer'}
              disabled={valueStep.some((step) => !step.answer)}
              onClick={handleNextStep}
              id={'submit'}
            />
          </div>
        </>
      )}

      {isProcessing && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <SpinnerCircular
            thickness={200}
            speed={150}
            color={Icon.color.Grey1}
            secondaryColor={Icon.color.Accent}
          />
          <p>Traitement en cours...</p>
        </div>
      )}
    </div>
  );
};

export default AISynthesisCreation;
