import React from 'react';
import { useToaster } from '../../../hooks/useToaster';
import { updateTest, sendInvitationTest } from '../../../api/CentralTestApi';
import Icon from '../../atoms/Icon/Icon';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import CircleButton from '../../atoms/CircleButton/CircleButton';

import './DataTable2.scss';
import IconButton from '../../atoms/IconButton/IconButton';
// DataTable.defaultProps = {
//   data: [], // keys are the column id (Replaces columns & data)
//   addToaster: () => {} | null, // (Remove & Replace by import useToaster)
//   setLoading: () => {} | null,
//   isConsultant: false, // (Not changed)
//   postResendTest = null, // (Never used on DataTable.js component (Removed))
//   officeInfo = null, // (Never used on DataTable.js component (Removed))
// };

const DataTable = ({ data, setLoading = null, isConsultant = false }) => {
  const { toaster, addToaster, removeToaster } = useToaster();

  /**
   * Renders a table with a message indicating no data is available
   * when the provided data is null or an empty array.
   */
  if (!data || data.length === 0) {
    return (
      <table className="data-table">
        <thead>
          <tr>
            <th id="empty">Aucune synthèse disponible actuellement.</th>
          </tr>
        </thead>
      </table>
    );
  }

  /**
   * Generates an array of column objects for a data table based on the keys of the first data entry.
   * Each column object contains an 'id' and a 'label', where the label is determined by the key name.
   * If the key matches a predefined case, a specific label is assigned; otherwise, a default label is used.
   *
   * @returns {Array<Object>} An array of column objects with 'id' and 'label' properties.
   */
  const columnsKeys = Object.keys(data[0]);
  const columns = columnsKeys.map((column) => {
    switch (column) {
      case 'name':
      case 'nameSynthesis':
        return { id: column, label: column === 'nameSynthesis' ? 'Nom de la synthèse' : 'Nom' };
      case 'status':
        return { id: column, label: 'Statut' };
      case 'actions':
        return { id: column, label: 'Actions' };
      case 'download':
        return { id: column, label: 'Télécharger' };
      case 'visibility':
        return { id: column, label: 'Visibilité' };
      case 'assessment_link':
        return { id: column, label: 'Accès du test' };

      default:
        return { id: column, label: ' ' };
    }
  });

  /**
   * Renders a table cell based on the specified column ID and value.
   *
   * @param {string} columnId - The identifier for the column to determine the rendering logic.
   * @param {Object} value - The data object containing values for the current row.
   * @returns {JSX.Element} A table cell element containing the appropriate content based on the column ID.
   *
   * The function handles various column IDs such as 'assessment_link', 'name', 'actions', 'download',
   * 'visibility', and 'report', rendering different components like CircleButton and Icon based on the column type.
   * It also includes logic for handling clicks on buttons and links, such as opening URLs or triggering specific actions.
   */
  const renderValue = (columnId, value) => {
    switch (columnId) {
      case 'assessment_link':
        return (
          <td key={columnId} id={columnId}>
            <CircleButton
              icon={Icon.icon.Quiz}
              onClick={() => window.open(value['assessment_link'])}
            />
          </td>
        );
      case 'name':
      case 'nameSynthesis':
        return (
          <td key={columnId} id={columnId}>
            {value.deleteSynthesis && (
              <IconButton
                color={Icon.color.Grey3}
                backgroundColor={Icon.color.White}
                icon={Icon.icon.Trash}
                onClick={() => value.deleteSynthesis()}
              />
            )}

            <Icon icon={Icon.icon.Question} />
            <p>{value[columnId]}</p>
          </td>
        );
      case 'actions':
        return (
          <td key={columnId} id={columnId}>
            <div>
              {!setLoading && value['actions'] && (
                <PrimaryButton
                  id={columnId + 'EditButton'}
                  label={'MODIFIER'}
                  icon={Icon.icon.Edit2}
                  outline={true}
                  onClick={() => value.actions()}
                />
              )}
              {!setLoading && !value['actions'] && <p>-</p>}

              {setLoading &&
                value['show_report'] &&
                value['id'] &&
                value['user_id'] &&
                value['assessment_id'](
                  <React.Fragment>
                    <CircleButton
                      color={value['show_report'] ? Icon.color.White : Icon.color.Accent}
                      backgroundColor={!value['show_report'] ? Icon.color.White : Icon.color.Accent}
                      icon={Icon.icon.Preview}
                      onClick={() => handleChangeShowReport(value['id'], value['show_report'])}
                    />
                    <CircleButton
                      color={Icon.color.White}
                      backgroundColor={Icon.color.Accent}
                      icon={Icon.icon.Reload}
                      onClick={() =>
                        handleReloadInvitation(
                          value['id'],
                          value['user_id'],
                          value['assessment_id'],
                          0,
                        )
                      }
                    />
                  </React.Fragment>,
                )}
            </div>
          </td>
        );
      case 'download': // TODO: More infos... (Icon(doc file & pdf / pdf only???), url? )
        return (
          <td key={columnId} id={columnId}>
            <div>
              {value[columnId] && (
                <IconButton
                  color={Icon.color.Grey3}
                  backgroundColor={Icon.color.White}
                  icon={Icon.icon.Download}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_API_MY_TALENTS_EVOLUTION}/aisynthesis/${value['id']}/pdf`,
                      '_blank',
                    )
                  }
                />
              )}
            </div>
          </td>
        );
      case 'visibility':
        return (
          <td key={columnId} id={columnId}>
            <label className="switch">
              <input
                type="checkbox"
                checked={value[columnId]}
                onChange={() => value.editVisible()}
              />
              <span className="slider"></span>
            </label>
          </td>
        );
      case 'id':
      case 'editVisible':
      case 'deleteSynthesis':
        return;
      case 'report': // TODO: Adapt to original format (company_report_pdf_link / show_report ???)
        return (
          <td key={columnId} id={columnId}>
            {value['company_report_pdf_link'] !== null && (value['show_report'] || isConsultant) ? (
              <a
                style={{
                  background: 'var(--main-color)',
                  color: 'white',
                  display: 'flex',
                  gap: '5px',
                  padding: '2px 1px',
                  border: '1px solid var(--main-color)',
                  borderRadius: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                href={value['company_report_pdf_link']}
                target="_blank"
              >
                <Icon icon={Icon.icon.Preview} color={'white'} />
                Consulter
              </a>
            ) : (
              'Non disponible'
            )}
          </td>
        );
      // Add new case here...

      default:
        return (
          <td key={columnId} id={columnId}>
            {value[columnId]}
          </td>
        );
    }
  };

  /**
   * Toggles the visibility of a test report for a specific test.
   *
   * @param {number} id - The unique identifier of the test.
   * @param {boolean} show_report - The current visibility status of the test report.
   * @returns {Promise<void>} - A promise that resolves when the test update is complete.
   *
   * Updates the test's `show_report` property and sends the update to the server.
   * Displays a success or error message based on the outcome of the update operation.
   */
  const handleChangeShowReport = async (id, show_report) => {
    const new_test = data.find((test) => test.id === id);
    if (show_report) {
      new_test.show_report = false;
      try {
        await updateTest(new_test);
        addToaster('Le rapport du test est maintenant visible par le talent', '', 'success');
      } catch (error) {
        addToaster('Erreur lors de la mise à jour du test', error.message, 'error');
      }
      addToaster("Le rapport du test n'est plus visible par le talent", '', 'success');
    } else {
      new_test.show_report = true;
      try {
        await updateTest(new_test);
        addToaster('Le rapport du test est maintenant visible par le talent', '', 'success');
      } catch (error) {
        addToaster('Erreur lors de la mise à jour du test', error.message, 'error');
      }
    }
  };

  /**
   * Handles the process of reloading an invitation by sending a request to the server.
   * Displays a success message if the invitation is sent successfully, otherwise shows an error message.
   *
   * @param {string} test_id - The ID of the test.
   * @param {string} user_id - The ID of the user.
   * @param {string} assesment_id - The ID of the assessment.
   * @param {string} centraltest_company_id - The ID of the central test company.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const handleReloadInvitation = async (test_id, user_id, assesment_id, centraltest_company_id) => {
    setLoading(true);
    try {
      const message = await sendInvitationTest({ test_id, user_id, assessment_id: assesment_id });
      addToaster('Invitation', message?.message ?? '', 'success');
    } catch (error) {
      addToaster("Erreur lors de l'envoi de l'invitation", error.message, 'error');
    }
    setLoading(false);
  };

  return (
    <div className="wrapper-table">
      <table className="data-table">
        <thead>
          <tr>
            {columns.map((column) => {
              if (
                column.id === 'editVisible' ||
                column.id === 'id' ||
                column.id === 'deleteSynthesis'
              ) {
                return null;
              } else {
                return <th key={column.id}>{column.label}</th>;
              }
            })}
          </tr>
        </thead>

        <tbody>
          {data.map((value, index) => (
            <tr key={index}>
              {columns.map((column) => {
                return renderValue(column.id, value);
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
