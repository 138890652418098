const AccompCoFormationLocale = {
    formationTypeLabel: "Type de formation",
    nameFormationLabel: "Nom de la formation",
    opinionConsultantLabel: "Avis du consultant",
    financingLabel: "Financement",
    startDateLabel: "Début de la formation",
    endDateLabel: "Fin de la formation",
    durationLabel: "Durée de la formation (en h)",
    costLabel: "Coût de la formation (en €)",
    monthLabels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    dayShortLabels: ['lun', 'mar', 'mer', 'jeu', 'ven', 'sam', 'dim'],
}

const optionsTypeFormation = [
    { value: " ", label: "..." },
    { value: 'other', label: 'Autre' },
    { value: 'adaptation', label: 'Adaptation' },
    { value: 'reconversion', label: 'Reconversion' },
    { value: 'diploma', label: 'Diplomante' },
    { value: 'certification', label: 'Certifiante' },
    { value: 'qualification', label: 'Qualifiante' },
    { value: 'vae', label: 'VAE' },
]


const optionsOpinionConsultant = [
    { value: " ", label: "..." },
    { value: 'no-opinion', label: 'Ne se prononce pas' },
    { value: 'favorable', label: 'Favorable' },
    { value: 'with-reservation', label: 'Sous réserve' },
]



const optionsFinancing = [
    { value: " ", label: "..." },
    { value: 'none', label: 'Aucun' },
    { value: 'cpf', label: 'CPF' },
    { value: 'training-plan', label: 'Plan de formation' },
    { value: 'career-transition', label: 'Transition professionnelle' },
    { value: 'pse-pdv-rcc', label: 'Mesures PSE, PDV, RCC' },
    { value: 'pole-emploi', label: 'France travail' },
    { value: 'opco', label: 'OPCO' },
    { value: 'employee', label: 'Salarié' },
    { value: 'others', label: 'Autres' },
]


export {
    AccompCoFormationLocale,
    optionsTypeFormation,
    optionsOpinionConsultant,
    optionsFinancing
}