/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Page from '../../containers/global/Page/Page';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout';
import LineMenu from '../../components/organisms/LineMenu/LineMenu';
import ParcoursCard from '../../components/organisms/ParcoursCard/ParcoursCard';
import InfoPage from '../../components/molecules/InfoPage/InfoPage';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { actions as CourseActions, selectors as CourseSelectors } from '../../redux/CourseRedux';
import {
  actions as NavigationActions,
  selectors as NavigationSelectors,
} from '../../redux/NavigationRedux';
import './BeneficiaryCoursesPage.scss';
import { getCourses } from '../../api/CourseApi';
import { round } from 'lodash';

import BeneficiaryIASynthesisPage from '../BeneficiaryIASynthesisPage/BeneficiaryIASynthesisPage';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
  courses: CourseSelectors.courses(state),
  pathname: NavigationSelectors.pathname(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCourses: (filter) => dispatch(CourseActions.getCourses(filter)),
  resetCurrentCourse: () => dispatch(CourseActions.setCourse(null)),
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const BeneficiaryCoursesPage = (props) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCourses = async () => {
    setIsLoading(true);
    getCourses(props.user.id)
      .then((response) => {
        setCourses(response.courses);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const renderTabs = () => {
    const links = [{ id: 'my_courses', label: 'Mes parcours' }];
    return <LineMenu value="my_courses" links={links} />;
  };

  const handleCardClick = ({ id }) => {
    props.redirect(`${props.pathname}/${id}`);
  };

  const renderCards = () => {
    if (!isLoading && courses && courses.length === 0) {
      return (
        <div className="no-courses-wrapper">
          <InfoPage text={"Vous n'avez pas de parcours."} />
        </div>
      );
    } else if (isLoading) {
      return <LoadingSpinner />;
    } else {
      return (
        <>
          {courses &&
            courses.map(
              ({
                id,
                title,
                cover_url,
                steps,
                percentage_done,
                nb_steps,
                nb_modules,
                nb_modules_done,
              }) => {
                const modulesDonePercentage = round((nb_modules_done / nb_modules) * 100);
                return (
                  <ParcoursCard
                    course={{
                      id,
                      coverUrl: cover_url,
                      title,
                      stepsCount: nb_steps,
                      modulesCount: nb_modules,
                      percentage_done: modulesDonePercentage,
                    }}
                    key={id}
                    onCardClick={handleCardClick}
                  />
                );
              },
            )}
        </>
      );
    }
  };

  const renderContent = () => <div className="course-card-list">{renderCards()}</div>;

  const aside = <BeneficiaryPageAside />;
  const content = <TabbedContentLayout header={renderTabs()} content={renderContent()} />;

  return (
    <div className="beneficiary-courses-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Page(BeneficiaryCoursesPage));
