import { axiosInstance } from "./baseApi";


export const getThemeColor = async(office_id) => {
    const response = await axiosInstance.get(`/offices/${office_id}/theme_color`); 
    return response.data;
}

export const updateThemeColor = async(data) => {
    const response = await axiosInstance.put(`offices/theme_color/update`, data);
    return response.data;
}

export const getOfficeById = async(office_id) => {
    const response = await axiosInstance.get(`/offices/${office_id}`);
    return response.data;
}