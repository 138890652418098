import React, { useState, Fragment } from 'react';
import InputField from '../InputField/InputField';
import IconButton from '../../atoms/IconButton/IconButton';
import Icon from '../../atoms/Icon/Icon';

import './AISynthesisNameEdition.scss';

const EditSynthesisNameEdition = ({ name, onSave, ...props }) => {
  const [currentName, setCurrentName] = useState(name);
  const [editMode, setEditMode] = useState(false);

  const handleChange = ({ value }) => {
    setCurrentName(value);
  };

  const handleValidate = () => {
    if (currentName.trim() === '') {
      alert('Le nom de la synthèse ne peut pas être vide.');
      return;
    }

    if (currentName.trim() === name) {
      setEditMode(false);
      return;
    }

    onSave(currentName);
    setEditMode(false);
  };

  const handleCancel = () => {
    setCurrentName(name);
    setEditMode(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        minWidth: '320px',
      }}
    >
      {!editMode && (
        <Fragment>
          <h2>{currentName}</h2>
          <IconButton
            icon={Icon.icon.Edit}
            color={Icon.color.Accent}
            onClick={() => setEditMode(true)}
          />
        </Fragment>
      )}

      {editMode && (
        <Fragment>
          <InputField
            title="Nom de la synthèse"
            placeholder="Nom de la synthèse..."
            id="synthesisName"
            name="synthesisName"
            value={currentName}
            type="text"
            onChange={handleChange}
            required
            autoComplete={false}
            autoFocus
          />
          <IconButton icon={Icon.icon.Check} color={Icon.color.Accent} onClick={handleValidate} />
          <IconButton icon={Icon.icon.Close2} color={Icon.color.Accent} onClick={handleCancel} />
        </Fragment>
      )}
    </div>
  );
};

export default EditSynthesisNameEdition;
