import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import ConversationItem from "./ConversationItem";
import { connect } from "react-redux";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";
import Icon from "../atoms/Icon/Icon";
import { debounce } from 'lodash';

const mapStateToProps = (state) => ({
    socket: ChatSelectors.setWebSocket(state),
})

function ConversationList(props) {
    const [conversationList, setConversationList] = useState([]);
    const [sortedConversationList, setSortedConversationList] = useState([])
    const socket = props.socket;
    const conversationRef = useRef();
    const conversationMobileRef = useRef();
    const [lastMessages, setLastMessages] = useState([]);

    const handleClick = (e) => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.add("conversation-mobile");
        conversationListMobile.classList.add("conversation-mobile");
    }

    const handleOutsideClick = useCallback((e) => {
        if (conversationRef.current && !conversationRef.current.contains(e.target) && conversationMobileRef.current && !conversationMobileRef.current.contains(e.target)) {
            const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
            const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

            conversationContainerList.classList.remove("conversation-mobile");
            conversationListMobile.classList.remove("conversation-mobile");
        }
    }, [])

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [handleOutsideClick])

    const handleClose = () => {
        const conversationContainerList = document.getElementsByClassName("conversation-container")[0];
        const conversationListMobile = document.getElementsByClassName("conversation-container-mobile")[0];

        conversationContainerList.classList.remove("conversation-mobile");
        conversationListMobile.classList.remove("conversation-mobile");
    }

    const syncConversations = () => {
        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/room/create/" + props.userrole, {
            params: {
                id_user: props.idUser
            }
        }).then(function (res) {
        }).catch(function (err) {
        })
    }

    useEffect(() => {
        syncConversations();
    }, [])

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    const handleSearchKeyUp = (event) => {
        event.preventDefault()
        const searchValue = event.target.value
        debounceSearch(searchValue, conversationList)
    }

    const debounceSearch = useCallback(
        debounce((searchValue, conversationList) => {
            search(searchValue, conversationList);
        }, 500),
    );

    const search = (searchValue, conversationList) => {
        const normalizedSearchValue = removeAccents(searchValue).toLowerCase();

        const updatedConversations = conversationList.map(conversation => {
            const parsed_name = parseInt(conversation.acquaintance_id) === parseInt(props.idUser) ? conversation.user_name : conversation.acquaintance_name;
            const name = parsed_name == null ? "Manager " + conversation.office_name : parsed_name;
            return {
                ...conversation,
                name: name
            }
        })

        if (normalizedSearchValue === "") {
            setSortedConversationList(conversationList);
            return;
        }

        const matchedResults = []
        const unmatchedResults = []

        updatedConversations.forEach((conversation) => {
            if (conversation.name === null) {
                unmatchedResults.push(conversation)
            } else {
                const normalizedConversationName = removeAccents(conversation.name).toLowerCase();
                (normalizedConversationName.includes(normalizedSearchValue) ? matchedResults : unmatchedResults).push(conversation);
            }
        })

        const sortedResults = [...matchedResults, ...unmatchedResults];

        setSortedConversationList(sortedResults);
    }

    const convertToLastMessageString = (lastMessage) => {
        if (!lastMessage) {
            return ""
        }

        if (lastMessage.is_deleted) {
            return "<Supprimé...>"
        }

        if (lastMessage.type === "text" || lastMessage.type === "visio") {
            return lastMessage.content
        }

        return "<Fichier...>"
    }

    const retrieveConversationList = (idUser) => {
        axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/rooms", {
            params: {
                id_user: idUser
            }
        }).then(function (res) {
            setConversationList(res.data);
            setSortedConversationList(res.data)
        }).catch(function (err) {
        })
    }

    useEffect(() => {
        retrieveConversationList(props.idUser)
    }, [props.idUser])

    const retrieveLastMessageByRoom = (conversationList) => {
        if (conversationList.length <= 0) {
            return
        }

        const roomKeys = conversationList.map((conversation) => conversation.room_key)

        if (roomKeys.length > 0) {
            axios.get(process.env.REACT_APP_API_MY_TALENTS_CHAT + "chat/rooms/last-message", {
                params: {
                    room_keys: JSON.stringify(roomKeys),
                }
            }).then((res) => {
                const newLastMessages = res.data.reduce((acc, message) => {
                    acc[message.room_key] = convertToLastMessageString(message);
                    return acc;
                }, {});

                setLastMessages(newLastMessages)
            })
        }
    }

    useEffect(() => {
        retrieveLastMessageByRoom(conversationList)
    }, [conversationList])

    useEffect(() => {
        if (props.socketReady) {
            const handleSocketMessage = (e) => {
                const message = JSON.parse(e.data);

                let newValue = ""

                if (message.type === "pong") {
                    return
                }

                if (message.type === "deletion") {
                    newValue = "<Supprimé...>"
                } else {
                    newValue = message.content
                }

                setLastMessages(prevLastMessages => ({
                    ...prevLastMessages, [message.room_key]: newValue
                }))
            };

            socket.addEventListener("message", handleSocketMessage);

            return () => {
                socket.removeEventListener("message", handleSocketMessage);
            };
        }
    }, [props.socketReady, socket, setLastMessages])

    return (
        <>
            <div ref={conversationRef} className="container-fluid conversation-container">
                <div className="row">
                    <p className="mt-2 pt-3 conversation-title">Mes conversations</p>
                    <button onClick={handleClose} className="close-conversation-container"></button>
                </div>
                <div className="conversation-search">
                    <Icon icon={Icon.icon.Search} />
                    <input type="text" className="conversation-search-input" onKeyUp={handleSearchKeyUp} />
                </div>
                {sortedConversationList.map(function (conversation) {
                    const parsed_name = parseInt(conversation.acquaintance_id) === parseInt(props.idUser) ? conversation.user_name : conversation.acquaintance_name;
                    const name = parsed_name == null ? "Manager " + conversation.office_name : parsed_name;
                    const profile_picture = parseInt(conversation.acquaintance_id) === parseInt(props.idUser) ? conversation.user_photo : conversation.acquaintance_photo;
                    conversation.profile_picture = profile_picture
                    return (
                        <ConversationItem
                            key={conversation.id}
                            roomKey={conversation.room_key}
                            photo={profile_picture}
                            name={name}
                            activeName={props.activeName}
                            conversation={conversation}
                            onData={props.onData}
                            lastMessage={lastMessages[conversation.room_key]}
                            idUserToNotify={props.idUser}
                        />
                    )
                })}
            </div>
            <div className={`container-fluid conversation-container-mobile`}>
                <img ref={conversationMobileRef} src="../static/icons/users-list.svg" alt="user list" onClick={(e) => handleClick(e)} />
            </div>
        </>
    )
}

export default connect(mapStateToProps, null)(ConversationList);