import React from 'react'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import Icon from '../../atoms/Icon/Icon'
import CircleButton from '../../atoms/CircleButton/CircleButton'
import './DisabledConsultantCard.scss'

const DisabledConsultantCard = ({ firstName, lastName, email, phone, avatar, id, disabled = false, onActivate }) => {
  const handleActivate = () => {
    if (disabled) {
      return
    }
    onActivate({ id })
  }

  return (
    <div className="disabled-consultant-card">
      <div className="top-container" style={{ cursor: 'default' }}>
        <div className="avatar-container">
          <AvatarCircle photoUrl={avatar || ''} />
        </div>
        <div className="informations">
          <div className="fullname">
            {firstName} {lastName}
          </div>
          <div className="small-informations">
            <div className="entry email">{email}</div>
            <div className="entry phone">{phone}</div>
          </div>
        </div>
      </div>

      <div className="bottom-container" style={{ cursor: !disabled && 'pointer' }}
        onClick={handleActivate}
      >
        Activer ce consultant &nbsp;
        <CircleButton
          disabled={disabled}
          isActivated={!disabled}
          icon={Icon.icon.PersonPlus}
          id={id}
          onClick={handleActivate}
        />
      </div>
    </div >
  )
}


export default DisabledConsultantCard;
