import React, { useState } from "react";
import { connect } from "react-redux";

import Icon from "../atoms/Icon/Icon";
import IconButton from "../atoms/IconButton/IconButton";
import { selectors as ChatSelectors } from "../../redux/ChatRedux";
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { createVisioRoom } from "../../api/TwilioApi";

const mapStateToProps = (state) => ({
    socket: ChatSelectors.setWebSocket(state),
    globalUser: UserSelectors.user(state)
})

function ActiveConversation(props) {
    const displayVisio = props.globalUser.role === "consultant" || props.globalUser.role === "manager" || props.globalUser.role === "admin";
    const [visioDisabled, setVisioDisabled] = useState(false);
    const socket = props.socket;

    const handleVideoCallInviteClick = (room_key) => {
        setVisioDisabled(true);

        createVisioRoom(room_key);

        const join_url = process.env.REACT_APP_VISIO_FRONT_URL + room_key

        const message = {
            random_key: (Math.random() * (40000 - 20000) + 20000) + "_" + (Math.random() * (20000 - 10000) + 10000),
            id_room: props.activeConversation.id,
            room_key: room_key,
            id_author: props.activeConversation.user_id,
            id_receiver: props.activeConversation.acquaintance_id,
            content: "Rejoignez-moi en visio grâce au lien suivant : " + join_url,
            date_emitted: new Date().toLocaleString(),
            type: "visio",
            author_name: props.activeConversation.user_name
        };

        socket.send(JSON.stringify(message));

        setTimeout(() => {
            setVisioDisabled(false);
        }, 5000);
    }

    if (!props.activeConversation) {
        return null
    }

    return (
        <div className="container-active-conversation">
            <div style={{ display: "flex" }}>
                <img src={!props.activeConversation.profile_picture ? "../static/icons/person-missing.svg" : `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(props.activeConversation.profile_picture).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`} alt=" " />
                <p><span className="at-symbol">@</span>{props.nameActiveConversation}</p>
            </div>
            {displayVisio &&
                <div className={`visio ${visioDisabled ? "visio-disabled" : ""}`} >
                    <IconButton
                        icon={Icon.icon.Visio}
                        tooltip="Appel vidéo"
                        color={visioDisabled ? "grey1" : "accent"}
                        onClick={() => handleVideoCallInviteClick(props.activeConversation.room_key)}
                    />
                </div>
            }
        </div>
    )

}

export default connect(mapStateToProps)(ActiveConversation);